import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router'
import { ObjectHeader } from '../components/header/object-header'
import { FullPageCard } from '../components/misc/full-page-card'
import { LoadingWheel } from '../components/misc/loading-wheel'
import { PolicyDetail } from '../components/policy/policy-detail'
import { useAuth } from '../supabase'
import { ConfirmationDialog } from '../components/form/confirmation-dialog'

export const PolicyPage = () => {
  const navigate = useNavigate()
  let { id } = useParams()

  if (id === 'new') id = undefined

  const { supabase } = useAuth()
  const [deleteModalOpen, setIsDeleteModalOpen] = useState(false)

  /*------Query Data-------*/
  const [isLoading, setIsLoading] = useState(true)
  const [policyData, setPolicyData] = useState({})

  //Query the information of the user and associated project then pass it to all the sub pages
  const fetchData = async () => {
    const { data, error } = await supabase
      .from('policy')
      .select(
        `
        *, 
        framework_safeguard(*, framework(name)), 
        control(*), 
        risk(*), 
        user(*),
        group(*),
        current_version:policy_version!policy_fk_active_version_fkey (*, af_policy_version_attachment(*)),
        all_version:policy_version!policy_version_fk_policy_id_fkey (*, af_policy_version_attachment(*))
      `
      )
      .eq('id', id)
      .order('created_at', { foreignTable: 'policy_version', descending: true })
      .single()

    // Sort the `all_version` array by `created_at` in descending order
    if (data && data.all_version) {
      data.all_version.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }

    // Map the safeguards and add the framework name to each safeguard
    if (data && data.framework_safeguard) {
      data.framework_safeguard = data.framework_safeguard.map((safeguard) => ({
        ...safeguard,
        framework_name: safeguard.framework?.name || 'Unknown Framework', // Add framework name to each safeguard
      }))
    }

    setPolicyData(data ?? {})
    setIsLoading(false)
  }

  const handleTagChange = async (status) => {
    try {
      const { data, error } = await supabase.from('policy').update({ status: status }).eq('id', id)

      if (error) throw error
      fetchData()
    } catch (error) {
      console.error('Error updating policy:', error)
    }
  }

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false)
  }

  const handleClickDelete = () => {
    setIsDeleteModalOpen(true)
  }

  const handleDeleteModalConfirm = async () => {
    const { error } = await supabase.from('policy') .delete().match({ id: id })
    if (error) {
      console.error('Error deleting user:', error)
      return 
    }
    navigate('/app/policy')
  }

  useEffect(() => {
    if (id) {fetchData()}
  }, [supabase])

  const actions_buttons = [
    {
      text: 'Active',
      onClickHandler: () => { handleTagChange('ACTIVE') },
      disabled: policyData.status === 'ACTIVE'
    },
    {
      text: 'Draft',
      onClickHandler: () => { handleTagChange('DRAFT') },
      disabled: policyData.status === 'DRAFT'
    },
    {
      text: 'Archived',
      onClickHandler: () => { handleTagChange('ARCHIVED') },
      disabled: policyData.status === 'ARCHIVED'
    }
  ]

  console.log('policy data', policyData)

  return (
    <FullPageCard>
      <ObjectHeader
        title={'Policy - ' + policyData?.userdef_id ? policyData?.userdef_id : ''}
        backUrl={-1}
        actionButtonText={'Tag'}
        actionButtonOptions={actions_buttons}
        secondaryButtonText={'Delete'}
        onSecondaryButtonClick={handleClickDelete}
        noBorderBottom={true}
      />
      {/* Suppression de la politique */}
      <ConfirmationDialog
        isOpen={deleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
      />
      {isLoading && <LoadingWheel />}
      {!isLoading &&
        <PolicyDetail
          policyData={policyData}
          refetch={fetchData}
        />
      }
    </FullPageCard >
  )
}
