import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Tag } from '../../misc/tag'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { ObjectSearchFilter } from '../header/object-search-filter'
import { Tooltip } from '../misc/tooltip'
import { JTable } from '../table/j-table'

const columns = [
  { key: 'name', sortable: true, label: 'Name', style: '' },
  {
    key: 'user',
    label: 'Members',
    style: 'text-center whitespace-nowrap w-4',
    render: (e) => (
      <>
        {e?.user?.length > 0 && (
          <Tooltip
            message={
              <div className='flex flex-col gap-1'>
                {e.user.map((g) => (
                  <span key={g.id}>{g.name}</span>
                ))}
              </div>
            }
          >
            <Tag text={e.user.length} />
          </Tooltip>
        )}
        {e?.user?.length <= 0 && <Tag text={e.user.length} />}
      </>
    )
  },
  {
    key: 'created_at',
    sortable: true,
    label: 'creation date',
    style: 'text-center whitespace-nowrap w-4 px-6',
    render: (e) => {
      const date = new Date(e.created_at)
      const date_f = date.toISOString().split('T')[0]
      return <>{date_f}</>
    }
  }
]

//const values = Array.from({ length: Math.floor(Math.random() * (200 - 0 + 1)) + 0 }, generateRandomGroup);

// Update filterOptions with the unique values
const filterOptions = [
  {
    section_title: 'Tag',
    key: 'Tag',
    data: [
      {
        label: 'No Filter',
        value: 'no_filter_owner'
      }
    ]
  }
]

const initializeActiveFilters = () => {
  const initialFilters = {}

  filterOptions.forEach((section) => {
    section.data.forEach((filter) => {
      initialFilters[filter.value] = false // Default state can be set to false or true as needed
    })
  })
  return initialFilters
}

export const StakeholderGroupTable = () => {
  const navigate = useNavigate()

  /*---Database---*/
  const { supabase, session } = useAuth()
  const { userInfo } = useContext(UserInfoContext)

  const [isLoading, setIsLoading] = useState(true)
  const [groupList, setGrouplist] = useState([])
  const [queryError, setQuerryError] = useState(null)

  const fetchData = async () => {
    const { data, error } = await supabase.from('group').select('*, user(name, id)').eq('fk_project_id', userInfo?.project?.id)

    setQuerryError(error ?? null)
    setGrouplist(data ?? [])
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [supabase])
  /*--------------*/

  const [forceUpdate, setForceUpdate] = useState(false)
  const [filteredValues, setFilteredValues] = useState([])
  const [activeFilters, setActiveFilters] = useState(initializeActiveFilters())

  const handleFilterChange = (e) => {
    setActiveFilters({
      ...activeFilters,
      [e.target.name]: e.target.checked
    })
  }

  //Filter
  useEffect(() => {
    let filtered = groupList

    Object.keys(activeFilters).forEach((filterKey) => {
      const filterValue = activeFilters[filterKey]

      if (filterKey === 'search' && filterValue != '') {
        filtered = filtered.filter((item) => item?.name?.toLowerCase().includes(filterValue.toLowerCase()))
      }
    })

    setForceUpdate(!forceUpdate)
    setFilteredValues(filtered)
  }, [groupList, activeFilters])

  return (
    <>
      <ObjectSearchFilter onFilterChange={handleFilterChange} />

      <JTable
        columns={columns}
        value={filteredValues}
        handleRowClick={(e) => {
          navigate('/app/group/' + e.id)
        }}
        defaultSortedColumn='creation_date'
        defaultSortOrder='desc'
        pagination='True'
        key={forceUpdate}
        isLoading={isLoading}
      />
    </>
  )
}
