import { useState, useEffect } from 'react';
import { useAuth } from '../supabase';

export const useWorkflowData = (id) => {
  const { supabase } = useAuth();
  const [workflowData, setWorkflowData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const { data, error } = await supabase
        .from('workflow_template')
        .select(`
          *,
          workflow_template_steps(*, user(*), group(*))
        `)
        .eq('id', id)
        .single();
      
      if (error) throw error;

      if (data?.workflow_template_steps) {
        const updatedSteps = data.workflow_template_steps.map((step) => {
          let assigned = null;
          if (step.group) {
            assigned = { ...step.group, comboboxName: step.group.name, group: true };
          }
          if (step.user) {
            assigned = { ...step.user, comboboxName: step.user.name, group: false };
          }
          return { ...step, assigned };
        });
        data.workflow_template_steps = updatedSteps;
      }
      setWorkflowData(data ?? {});
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [id]);

  return { workflowData, isLoading, error, refetch: fetchData };
};
