import React, { createContext, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { Layout } from 'antd'
import { MainMenu } from '../components/main-menu'
import { useAuth } from '../supabase'

const { Sider, Content } = Layout

export const UserInfoContext = createContext()

export const RootPage = (props) => {
  const { supabase, session } = useAuth()
  const navigate = useNavigate()
  
  const {userInfo} = props

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
  
    navigate('/auth/login');
  }

  return (
    <UserInfoContext.Provider value={{ userInfo: userInfo }}>
    <div className='flex flex-col min-h-screen'>
      <MainMenu active={location.pathname} onSignoutClick={handleSignOut} isSuperUser={userInfo?.type === 'SUPERUSER'}/>
      <div className='flex-grow mx-8 mt-4'>
        <div className='flex justify-center  mx-auto lg:ml-72'>
          <Outlet />
        </div>
      </div>
    </div>
  </UserInfoContext.Provider>

  )
}
