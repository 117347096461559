import { CloseCircleOutlined } from '@ant-design/icons'
import { Transition } from '@headlessui/react'
import { Fragment } from 'react'
import React from 'react'
export const ErrorNotification = (props) => {
  const { title, text, onClose, show } = props

  return (
    <>
      <div aria-live='assertive' className='pointer-events-none fixed inset-x-0 bottom-0 flex items-end px-4 py-6 sm:items-start sm:p-6'>
        <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
          <Transition
            show={show}
            as={Fragment}
            enter='transform ease-out duration-300 transition'
            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='bg-red-100 border-l-4 border-red-500 text-red-700 p-2 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
              <div className='p-2'>
                <div className='flex items-start'>
                  <div className='ml-3 w-0 flex-1 pt-0.5'>
                    <p className='text-sm font-bold text-gray-900'>{title}</p>
                    <p className='mt-1 text-sm text-gray-500'>{text}</p>
                  </div>
                  <div className='ml-4 flex flex-shrink-0'>
                    <button
                      type='button'
                      className='inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                      onClick={onClose}
                    >
                      <span className='sr-only'>Close</span>
                      <CloseCircleOutlined className='h-5 w-5' aria-hidden='true' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}
