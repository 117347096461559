import React from 'react'

import { Controller, useForm } from 'react-hook-form'
import { ActionButton } from '../form/action-button'
import { FileUploader } from '../form/file-uploader'
import { TextInput } from '../form/inputs'

export const TaskResultForm = ({ onSubmit, initialData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    control
  } = useForm({
    defaultValues: {
      result: initialData?.id || "",
      attachments: initialData?.attachments || [],
    },
    mode: 'onChange',  // Validate in real-time
  });

  return (
    <>
      <form id='policy-edit-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='space-y-4'>
          <TextInput
            label='Result'
            name='result'
            rows={10}
            register_fn={() => {
              return register('result')
            }}
          />
          <div>
            <label className='block text-sm font-medium text-gray-700 mb-1'>Attachments</label>
            <Controller
              control={control}
              name='attachments'
              render={({ field: { value, onChange } }) => <FileUploader name='attachments' value={value} onChange={onChange} />}
            />
          </div>
        </div>

        <div className='flex justify-end gap-2 mt-2 border-t pt-2'>
          <ActionButton styleType='primary' type='submit'>
            Submit
          </ActionButton>
        </div>
      </form>
    </>
  )
}
