import React from 'react'
import { DeleteOutlined, SortAscendingOutlined } from '@ant-design/icons'
import { useMemo, useState } from 'react'
import { SmallButton } from '../form/action-button'
import { LoadingWheel } from '../misc/loading-wheel'
import { AddGroupPalette } from '../modal/add-group-palette'
import { AddUserPalette } from '../modal/add-user-palette'
import TablePagination from './pagination'

export const JTable = (props) => {
  const {
    title,
    pagination,
    columns,
    value,
    canEditData,
    handleRowClick,
    defaultSortedColumn,
    defaultSortOrder,
    isLoading,
    onChange,
    AddElementModalType,
    additionalAddFunction,
    additionalDeleteFunction,
    addText
  } = props

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 15

  const [sortField, setSortField] = useState(defaultSortedColumn ? defaultSortedColumn : null)
  const [sortDirection, setSortDirection] = useState(defaultSortOrder ? defaultSortOrder : 'asc')

  /*----Add user modal-----*/
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false)

  const handleClickAdduser = () => {
    setIsAddUserModalOpen(true)
  }

  const handleSaveAddUser = (newUser) => {
    if (newUser?.id) {
      onChange([...value, newUser])
    }
    setIsAddUserModalOpen(false)
  }

  const handleCancelAdduser = () => {
    setIsAddUserModalOpen(false)
  }

  const handleRowDeleteClick = (e, row) => {
    e.stopPropagation()

    if (additionalDeleteFunction) {
      additionalDeleteFunction(row)
    } else {
      onChange([...value.filter((e) => e.id !== row.id)])
    }
  }

  const handleSaveNewCheck = async (e) => {
    await additionalAddFunction(e)
    setIsAddUserModalOpen(false)
  }
  /*--------------------*/

  const sortedData = useMemo(() => {
    if (!value) return []
    if (!sortField) return value

    let sorted = [...value]

    if (columns[sortField]?.sort_fn) {
      sorted = sorted.sort((a, b) => {
        return columns[sortField].sort_fn(sortDirection, a, b)
      })
    } else {
      sorted = sorted.sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1
        if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1
        return 0
      })
    }

    return sorted
  }, [value, sortField, sortDirection])

  // Function to handle the sorting when a column header is clicked
  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortDirection('asc')
    }
  }

  // Calculate the currently displayed items
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = pagination ? sortedData.slice(indexOfFirstItem, indexOfLastItem) : sortedData

  // Function to change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  return (
    <div className='flex flex-col'>
      <div className='inline-block min-w-full align-middle '>
        <table className='min-w-full divide-y divide-gray-300 border border-gray-300 '>
          <thead className='cursor-default uppercase'>
            {title && (
              <tr className='bg-gray-200 border-b-2'>
                <th colSpan={columns.length + (canEditData ? 1 : 0)} className='px-3 py-3 text-left text-xs font-bold text-gray-900'>
                  {title}
                </th>
              </tr>
            )}
            <tr className='bg-gray-100'>
              {columns.map((c) => (
                <th
                  key={c.key}
                  scope='col'
                  className={`
                      px-3 py-2 text-xs font-bold uppercase text-gray-900 text-center
                      ${c.style} 
                      ${c.sortable ? 'cursor-pointer hover:bg-gray-200' : ''} 
                      ${sortField === c.key ? 'bg-gray-300' : ''}
                    `}
                  onClick={() => c.sortable && handleSort(c.key)}
                >
                  <div className='flex items-center justify-center'>
                    {c.label}
                    {c.sortable && <SortAscendingOutlined className='ml-2' />}
                  </div>
                </th>
              ))}
              {canEditData && <th key={'delete'} className='w-2'></th>}
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200 bg-white'>
            {currentItems.map((rowData) => (
              <tr
                key={rowData.id}
                className='hover:bg-gray-100 cursor-pointer'
                onClick={() => {
                  handleRowClick && handleRowClick(rowData)
                }}
              >
                {columns.map((column) => (
                  <td key={column.key} className={`py-1 pr-3 text-sm text-gray-700 px-4 ${column.style}`}>
                    {column.render ? column.render(rowData) : rowData[column.key]}
                  </td>
                ))}
                {canEditData && (
                  <td className='h-full px-4 w-2'>
                    <SmallButton onClick={(e) => handleRowDeleteClick(e, rowData)}>
                      <DeleteOutlined />
                    </SmallButton>
                  </td>
                )}
              </tr>
            ))}
            {!isLoading && (value === undefined || value.length === 0) && (
              <tr>
                <td colSpan={columns.length + (canEditData ? 1 : 0)} className='text-center py-4'>
                  <h3 className='text-sm font-medium text-gray-900'>No Data</h3>
                </td>
              </tr>
            )}
            {isLoading && (
              <tr>
                <td colSpan={columns.length + (canEditData ? 1 : 0)} className='text-center py-4'>
                  <LoadingWheel />
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {pagination && (
          <TablePagination itemsPerPage={itemsPerPage} total={value?.length ? value.length : 0} onPageChange={paginate} currentPage={currentPage} />
        )}
      </div>

      {canEditData && (
        <div className='mt-2 self-end'>
          <SmallButton onClick={handleClickAdduser}>
            {addText && <>{addText}</>}
            {!addText && <>Add</>}
          </SmallButton>
          {AddElementModalType === 'user' && (
            <AddUserPalette
              onAdd={handleSaveAddUser}
              onClose={handleCancelAdduser}
              isOpen={isAddUserModalOpen}
              alreadyused={currentItems}
            />
          )}
          {AddElementModalType === 'group' && (
            <AddGroupPalette
              onClose={handleCancelAdduser}
              onAdd={handleSaveAddUser}
              isOpen={isAddUserModalOpen}
              alreadyused={currentItems}
            />
          )}
        </div>
      )}
    </div>
  )
}
