import React, { useState } from 'react';
import { Tag } from '../../misc/tag';
import { ActionButton } from '../form/action-button';
import { TextInput } from '../form/inputs';
import { RenderGroupOrUser } from '../misc/columns_def';
import { useForm } from 'react-hook-form';

const RenderAction = (e) => {
  return (
    <>
      {e?.status === 'APPROVED' && <Tag type='success' text='APPROVED' />}
      {e?.status === 'REJECTED' && <Tag type='error' text='REJECTED ' />}
      {e?.status === 'PENDING' && <Tag type='yellow' text='PENDING' />}
    </>
  )
}

const RenderActionComp = ({ status }) => {
  return (
    <>
      {status === 'APPROVED' && <Tag type='success' text='APPROVED' />}
      {status === 'REJECTED' && <Tag type='error' text='REJECTED ' />}
      {status === 'PENDING' && <Tag type='yellow' text='PENDING' />}
    </>
  )
}

export const CompactDescriptionList = ({ title, items }) => {
  return (
    <div className="bg-white">
      <h2 className="text-base font-semibold text-gray-800 mb-1">{title}</h2>
      <dl className="divide-y divide-gray-100 ml-4">
        {items?.map((item, index) => (
          <div key={index} className="py-2 grid grid-cols-4 gap-2">
            <dt className="text-xs uppercase font-bold text-gray-800">{item.label}</dt>
            <dd className="text-sm text-gray-800 col-span-3">
              {item?.render ? item.render(item) : item.value}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

const NoteForm = ({ stepId, onSubmit, canAddNote }) => {
  const { register, handleSubmit, reset } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-2 border-t '>
      {canAddNote &&
        <TextInput
          label="Note"
          name="note"
          rows={5}
          register_fn={() => register('note', { required: false })}
        />
      }
      <div className="flex justify-end my-2 gap-2">
        <ActionButton type="submit" value="REJECTED">Decline</ActionButton>
        <ActionButton type="submit" value="APPROVED">Approve</ActionButton>
      </div>
    </form>
  );
}

const formatDate = (isoDateString) => {
  const date = new Date(isoDateString);
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true, // For 12-hour format, set to false for 24-hour format
    timeZoneName: 'short', // Optional, includes time zone
  }).format(date);
};

const getDataDescriptionList = (step) => {
  return ([
    { label: 'Name', value: step.step_name },
    { label: 'Description', value: step.step_description },
    { label: 'Assigned', render: () => <RenderGroupOrUser user={step?.assigned_user} group={step?.assigned_group} /> },
    { label: 'Permission to Add Notes', value: step.step_can_add_note ? 'YES' : 'NO' },
  ])
}

const getResultDescriptionList = (step) => {
  return ([
    { label: 'Timestamp', value: formatDate(step?.validated_timestamp) },
    { label: 'Approved by', render: () => <RenderGroupOrUser user={step?.user_validated} /> },
    { label: 'Action', render: () => RenderAction(step) },
    { label: 'Note', value: step.note },
  ])
}

export const WorkflowHistory = ({ data, onSubmit }) => {
  // Find the index of the first 'PENDING' status when the component mounts
  const firstPendingIndex = data?.findIndex(step => step.status === 'PENDING') ?? -1;

  // Set the initial state to open the first 'PENDING' item if it exists
  const [openIndexes, setOpenIndexes] = useState(firstPendingIndex !== -1 ? [firstPendingIndex] : []);


  const toggleAccordion = (index) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  return (
    <div className="hs-accordion-group">
      {data?.map((step, index) => {
        const isOpen = openIndexes.includes(index); // Check if this index is open
        const dataDescriptionList = getDataDescriptionList(step)
        const dataResultList = getResultDescriptionList(step)

        let bgColor = 'bg-slate-50'
        if (step?.status === 'PENDING') bgColor = 'bg-yellow-50'
        if (step?.status === 'APPROVED') bgColor = 'bg-green-50'
        if (step?.status === 'REJECTED') bgColor = 'bg-red-50'

        return (
          <div
            key={step.id}
            className="hs-accordion bg-white border -mt-px mt-4 shadow border"
            id={`hs-bordered-heading-${index}`}
          >
            <button
              onClick={() => toggleAccordion(index)}
              className={`hs-accordion-toggle ${bgColor} ${isOpen ? 'text-blue-600 border-b' : ''} inline-flex items-center gap-x-3 w-full font-semibold text-start text-gray-800 py-3 px-5 hover:bg-slate-50`}
              aria-expanded={isOpen}
              aria-controls={`hs-basic-bordered-collapse-${index}`}
            >
              <div className="flex items-center w-full">
                {/* Left part: SVGs and text */}
                <div className="flex items-center gap-x-2 flex-grow">
                  {/* Accordion toggle icons */}
                  <svg className={`${isOpen ? 'hidden' : 'block'} size-3.5`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M5 12h14"></path>
                    <path d="M12 5v14"></path>
                  </svg>
                  <svg className={`${isOpen ? 'block' : 'hidden'} size-3.5`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M5 12h14"></path>
                  </svg>
                  <span>{`[${step?.step_number + 1}/${data?.length}] ${step?.step_name}`}</span>
                </div>
                {/* Right part: Status Tag */}
                <div>
                  <RenderActionComp status={step.status} />
                </div>
              </div>
            </button>

            <div
              id={`hs-basic-bordered-collapse-${index}`}
              className={`hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${isOpen ? 'max-h-screen' : 'max-h-0'}`}
              role="region"
              aria-labelledby={`hs-bordered-heading-${index}`}
            >
              <div className="relative">
                {/* Gray left margin */}
                <div className="absolute left-0 top-0 bottom-0 w-12 bg-slate-50"></div>

                <div className="ml-12 px-4">
                  <CompactDescriptionList title={'Workflow'} items={dataDescriptionList} />

                  {step.status !== 'PENDING' && step.status !== 'N/A' &&
                    <>
                      <div className='border-b my-2'></div>
                      <CompactDescriptionList title={'Validation'} items={dataResultList} />
                    </>
                  }
                  {step.status === 'PENDING' && (
                    <NoteForm
                      stepId={step.id}
                      canAddNote={step.step_can_add_note}
                      onSubmit={(formData) => onSubmit(step.id, formData)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div >
  );
};
