import React, { useState } from 'react'

import { useNavigate } from 'react-router'
import { ObjectHeader } from '../components/header/object-header'
import { FullPageCard } from '../components/misc/full-page-card'
import { StakeholderTab } from '../components/stakeholder/stackholder-tab'
import { StakeholderGroupTable } from '../components/stakeholder/stakeholder-group-table'
import { StakeholderUserTable } from '../components/stakeholder/stakeholder-user-table'

//const data = Array.from({ length: Math.floor(Math.random() * (200 - 0 + 1)) + 0 }, generateRandomStakeholder)

export const StakeholderPage = () => {
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState('Users')

  const handleSelectedTabChange = (e) => {
    setSelectedTab(e)
  }

  const handleClickAddStakeholder = () => {
    if (selectedTab === 'Users') {
      navigate('/app/user/new')
    }
    if (selectedTab === 'Groups') {
      navigate('/app/group/new')
    }
  }

  return (
    <FullPageCard>
      <ObjectHeader
        title='Stakeholders'
        primaryButtonText={selectedTab === 'Users' ? 'New User' : 'New Group'}
        onPrimaryButtonClick={handleClickAddStakeholder}
      />
      <StakeholderTab selectedTab={selectedTab} onSelectedTabChange={handleSelectedTabChange} />
      <div className='mt-4'>
        {selectedTab === 'Users' && <StakeholderUserTable />}
        {selectedTab === 'Groups' && <StakeholderGroupTable />}
      </div>
    </FullPageCard>
  )
}
