import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { useJDrawer } from '../../hooks/useJDrawer'
import { useTaskData } from '../../hooks/useTaskData'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { SmallButton } from '../form/action-button'
import { TaskFullColumn } from '../misc/columns_def'
import JDrawer from '../misc/j-drawer'
import { LoadingWheel } from '../misc/loading-wheel'
import { TaskForm } from '../task/task-form'
import { JTable } from './j-table'

export const TasksTable = ({ associatedId, hideAddButton }) => {
  const navigate = useNavigate()
  const { userInfo } = useContext(UserInfoContext)
  const { supabase } = useAuth()

  const { taskData: taskList, isLoading, error, refetch } = useTaskData(supabase, null, associatedId);
  const { open, openDrawer, closeDrawer } = useJDrawer();
  const [isSaving, setIsSaving] = useState(false)

  const handleRowClick = (row) => {
    if (row?.id) {
      navigate('/app/task/' + row.id)
    }
  }

  const handleCreateTask = async (values) => {
    setIsSaving(true)

    let dateString = null
    if (values.duedate) {
      const date = new Date(values.duedate)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      dateString = `${year}-${month}-${day}`
    }

    const insertResult = await supabase.from('task').insert([
      {
        fk_project_id: userInfo.project.id,
        fk_associated_object_id: associatedId,
        status: 'TODO',
        duedate: dateString,
        title: values.title,
        userdef_id: values.userdef_id,
        description: values.description,

        fk_workflow_template_id:
          !values?.workflow_template_id?.id || values.workflow_template_id.id === 'no-validation'
            ? null
            : values.workflow_template_id.id,
        fk_executor_group_id: values.executor.group ? values.executor.id : null,
        fk_executor_user_id: !values.executor.group ? values.executor.id : null,
      }
    ])

    if (insertResult.error) console.error(insertResult.error)

    await refetch()
    closeDrawer(true)
    setIsSaving(false)
  }

  return (
    <>
      {isLoading && (
        <div className='my-8'>
          <LoadingWheel />
        </div>
      )}
      {!isLoading && (
        <>
          <div className='my-2'>
            {!hideAddButton && <SmallButton onClick={() => openDrawer()}>New Task</SmallButton>}
            <JDrawer title={"new task"} open={open} onClose={closeDrawer}>
              <>
                {!isSaving &&
                  <TaskForm
                    onlySchedule={true}
                    onSubmit={handleCreateTask}
                  />
                }
                {isSaving && <LoadingWheel />}
              </>
            </JDrawer>
          </div>
          <JTable
            columns={TaskFullColumn}
            handleRowClick={handleRowClick}
            value={taskList}
            pagination={true}
            canEditData={false}
          />
        </>
      )}
    </>
  )
}
