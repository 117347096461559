import { RRule } from 'rrule'

export const formatDateToRRULEString = (date) => {
  // Padding function to ensure two-digit parts
  const pad = (number) => number.toString().padStart(2, '0')

  // Extracting parts of the date
  const year = date.getFullYear()
  const month = pad(date.getMonth() + 1) // getMonth() is zero-indexed, add 1
  const day = pad(date.getDate())

  // Concatenating all parts
  const dateString = `${year}${month}${day}`
  return dateString
}

export const formatDateToDateWithoutTimezone = (date) => {
  const pad = (number) => number.toString().padStart(2, '0')

  const year = date.getFullYear()
  const month = pad(date.getMonth() + 1) // getMonth() is zero-indexed, add 1
  const day = pad(date.getDate())

  const dateString = `${year}-${month}-${day}`

  return dateString
}

export const nextDateFromRRule = (rrule, date) => {
  const newrrule = rrule //+ ';UNTIL=' + formatDateToRRULEString(new Date());
  let r = RRule.parseString(newrrule)
  const rule = new RRule(r)

  const newDate = rule.after(date, true)
  console.log('ruleeee', newDate)
  return newDate
}

export const formatDateAndTime = (dateString) => {
  const date = new Date(dateString)
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }
  const formattedDate = date.toLocaleDateString(undefined, options)

  return formattedDate
}
