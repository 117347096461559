import { useContext, useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { useAuth } from '../../supabase';
import { AuthCard } from '../../components/auth/auth-card';
import { TextInput } from '../../components/form/inputs';
import { useForm } from 'react-hook-form';
import { ActionButton } from '../../components/form/action-button';
import { Navigate, useNavigate } from 'react-router';
import { UserInfoContext } from '../../page/root-page'

export const UpdatePasswordPage = () => {
  const { supabase, session } = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    control
  } = useForm({ defaultValues: {}, mode: 'all' });

  const fetchData = async () => {
    if (session?.user?.id) {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('user')
        .select('*, project(*), group(*)')
        .eq('fk_auth_id', session?.user?.id)
        .single();

      if (error) {
        setErrorMessage(error.message);
      } else {
        console.log('data', data);
        if (data) {
          setValue('tenant', data?.project?.name);
          setValue('email', data?.email);
          setValue('type', data?.type);
        }
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [supabase]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    setErrorMessage(null);

    const { password } = data;

    try {
      const { error } = await supabase.auth.updateUser({
        password: password
      });

      if (error) { throw error; }

      const { data, error: e2 } = await supabase
        .from('user')
        .update({ password_updated: true })
        .eq('fk_auth_id', session?.user?.id);

      if (e2) { throw e2; }

      // Handle successful password update (e.g., navigate to login page)
      setErrorMessage(null);
      alert('Password updated.');
      window.location.reload();
    } catch (error) {
      if (error?.message) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("An unexpected error occurred. Please contact support.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <></>;

  return (
    <div className='h-screen bg-slate-50 flex items-center justify-center'>
      <AuthCard>
        <span className='font-semibold text-gray-800 text-center'>
          To finalize your account creation, please set up a password.<br /><br />
        </span>
        <form id='control-edit-form' onSubmit={handleSubmit(onSubmit)} className='flex flex-col items-center gap-4 w-full'>
          <TextInput
            label='Tenant'
            name='tenant'
            disabled
            register_fn={() => { return register('tenant') }}
          />
          <TextInput
            label='Email'
            name='email'
            disabled
            register_fn={() => { return register('email') }}
          />
          <TextInput
            label='Account Type'
            name='type'
            disabled
            register_fn={() => { return register('type') }}
          />
          <TextInput
            label='Password'
            name='password'
            type="password"
            errorMessage={errors?.password ? 'Password requirements: at least 8 characters long, one uppercase letter, one number, one special character' : null}
            register_fn={() => {
              return register('password', {
                required: 'Password is required',
                maxLength: { value: 64, message: 'Password cannot exceed 64 characters' },
                validate: value => {
                  const hasUpperCase = /[A-Z]/.test(value);
                  const hasNumber = /\d/.test(value);
                  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
                  const hasMinLength = value.length >= 8;
                  return hasUpperCase && hasNumber && hasSpecialChar && hasMinLength || 'Password does not meet requirements';
                }
              });
            }}
          />
          {errorMessage && <span className='text-red-600 font-semibold'>{errorMessage}</span>}

          <ActionButton disabled={isLoading} onClick={() => { }} type="submit">
            Register password
          </ActionButton>
        </form>
      </AuthCard>
    </div>
  );
};
