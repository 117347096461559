import React, { useContext, useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router'
import { ObjectHeader } from '../components/header/object-header'
import { FullPageCard } from '../components/misc/full-page-card'
import { useAuth } from '../supabase'
import { useForm } from 'react-hook-form'
import { ActionButton } from '../components/form/action-button'
import { LoadingWheel } from '../components/misc/loading-wheel'
import { PolicyRevisionFormPanel, PolicyGeneralFormPanel } from '../components/policy/policy-form'
import { UserInfoContext } from './root-page'
import uuid from 'react-uuid'

export const EditPolicyPage = () => {
  const navigate = useNavigate()
  const { supabase } = useAuth()
  const { userInfo } = useContext(UserInfoContext)
  let { id, revisionid } = useParams()
  const isNewPolicy = id === undefined

  const defaultValues = {
    userdef_id: '',
    title: '',
    description: '',
    revision_userdef_id: '',
    attachments: [],
    owner: null
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    control
  } = useForm({ defaultValues: defaultValues, mode: 'onBlur' })

  const [isLoading, setIsLoading] = useState(id ? true : false)
  const handleClickCancel = () => { navigate(-1) }

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)

      // Upsert policy revision
      if (revisionid) {
        const payloadPolicyRevision = {
          id: revisionid,
          description: data.description,
          userdef_id: data.revision_userdef_id,
          fk_policy_id: id,
          fk_project_id: userInfo.project.id
        }

        console.log('payloadPolicyRevision', payloadPolicyRevision)

        const { error: errorRevision } = await supabase.from('policy_version').upsert({ ...payloadPolicyRevision })
        if (errorRevision) throw errorRevision

        // Fetch existing attachments for the revision
        const { data: existingAttachments, error: fetchAttachmentsError } = await supabase
          .from('af_policy_version_attachment')
          .select('*')
          .eq('fk_policy_version_id', revisionid);

        if (fetchAttachmentsError) throw fetchAttachmentsError;

        // Create a set of attachment IDs to easily track existing attachments
        const existingAttachmentIds = new Set(existingAttachments.map((att) => att.attachment_id));

        // Create a set of new attachment IDs for comparison
        const newAttachmentIds = new Set(data.attachments.map((file) => file.attachment_id));

        // Find attachments to delete (existing but not in the new list)
        const attachmentsToDelete = existingAttachments.filter((att) => !newAttachmentIds.has(att.attachment_id));

        // Construct the folder path for deletion
        const folderPath = `${userInfo.project.id}/policy/${id}/${revisionid}/`;

        // Delete attachments that are no longer needed
        if (attachmentsToDelete.length > 0) {
          const deletePromises = attachmentsToDelete.map((att) => {
            const filePath = `${folderPath}/${att.attachment_id}`;
            return supabase.storage.from('project_data').remove([filePath]);
          });

          const deleteResults = await Promise.all(deletePromises);
          deleteResults.forEach((result) => {
            if (result.error) {
              throw result.error;
            }
          });

          // Delete records from the database
          const { error: deleteAttachmentError } = await supabase
            .from('af_policy_version_attachment')
            .delete()
            .in('attachment_id', attachmentsToDelete.map((att) => att.attachment_id));

          if (deleteAttachmentError) throw deleteAttachmentError;
        }

        // Upload new attachments and update the database
        const fileUploadPromises = data.attachments.map(async (file) => {
          if (!existingAttachmentIds.has(file.attachment_id)) {
            // Only upload new files
            const filePath = `${folderPath}/${file.attachment_id}`;

            // Upload file
            const uploadResult = await supabase.storage.from('project_data').upload(filePath, file);
            if (uploadResult.error) throw uploadResult.error;

            // Insert record into policy_attachments table
            const insertResult = await supabase.from('af_policy_version_attachment').insert([
              {
                fk_policy_version_id: revisionid,
                attachment_id: file.attachment_id,
                name: file.name,
                size: file.size,
                file_url: filePath,
                fk_project_id: userInfo.project.id
              }
            ]);
            if (insertResult.error) throw insertResult.error;
          }
        });

        await Promise.all(fileUploadPromises);
      }

      // Upsert policy general
      else {
        const policyPayload = {
          ...(data?.id && { id: data.id }),
          title: data.title,
          userdef_id: data.userdef_id,
          fk_project_id: userInfo.project.id,
          fk_owner_group_id: data.owner.group ? data.owner.id : null,
          fk_owner_user_id: !data.owner.group ? data.owner.id : null
        }

        const { error: errorRevision } = await supabase.from('policy').upsert({ ...policyPayload })
        if (errorRevision) throw errorRevision
      }

      setIsLoading(false)
      navigate(-1)
    } catch (e) {
      console.log('Error upserting policy', e)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    //modifie une revision
    if (revisionid) {
      supabase
        .from('policy_version')
        .select('userdef_id, description, af_policy_version_attachment(*)')
        .eq('id', revisionid)
        .single()
        .then(({ data, error }) => {
          if (error) {
            console.error('Error fetching revision:', error)
            return
          }

          setValue('id', data.id)
          setValue('revision_userdef_id', data.userdef_id)
          setValue('description', data.description)
          setValue('attachments', data.af_policy_version_attachment)
          setIsLoading(false)
        })
    }
    //modifie une politique
    else if (id) {
      supabase
        .from('policy')
        .select('*, user(*), group(*)')
        .eq('id', id)
        .single()
        .then(({ data, error }) => {
          if (error) {
            console.error('Error fetching group:', error)
            return
          }

          setValue('id', data.id)
          setValue('userdef_id', data.userdef_id)
          setValue('title', data.title)
          setValue('description', data.description)
          if (data.group) {
            setValue('owner', { ...data.group, comboboxName: data.group.name, group: true })
          }
          if (data.user) {
            setValue('owner', { ...data.user, comboboxName: data.user.name, group: false })
          }
          setIsLoading(false)
        })
    }
  }, [id, supabase])

  return (
    <FullPageCard>
      <ObjectHeader
        title={'Policy'}
        backUrl={-1}
      />
      <form id='policy-edit-form' onSubmit={handleSubmit(onSubmit)}>
        {isLoading && <LoadingWheel />}

        {!isLoading && revisionid && !isNewPolicy &&
          <PolicyRevisionFormPanel
            register={register}
            control={control}
            errors={errors}
          />
        }

        {!isLoading && !revisionid &&
          <PolicyGeneralFormPanel
            register={register}
            control={control}
            errors={errors}
          />
        }

        <div className='flex justify-end gap-2 mt-2 border-t pt-2'>
          <ActionButton disabled={isLoading} onClick={handleClickCancel} styleType='secondary' type='button'>
            Cancel
          </ActionButton>
          <ActionButton disabled={isLoading || !isValid} styleType='primary' type='submit' >
            Save
          </ActionButton>
        </div>
      </form>
    </FullPageCard>
  )
}
