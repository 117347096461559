import React, { useContext, useState } from 'react'
import { ElementDetail, RenderFileUploaded } from '../element/element-detail'
import { ConnectionTable } from '../table/connection-table'
import { TasksTable } from '../table/tasks-table'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { formatDateAndTime } from '../misc/time'
import { RenderGroupOrUser, StatusTag } from '../misc/columns_def'
import { PolicyVersioning } from './policy-versioning'
import { useNavigate } from 'react-router'

const tabs = [
  { name: 'Description', href: '' },
  { name: 'Versions', href: '' },
  { name: 'Tasks', href: '' },
  { name: 'Connections', href: '' }
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const PolicyDetail = (props) => {
  const { userInfo } = useContext(UserInfoContext)
  const { supabase } = useAuth()
  const navigate = useNavigate()

  const { policyData, refetch } = props

  const [selectedTab, setSelectedTab] = useState('Description')

  const policy_spec = [
    {
      key: 'tag',
      label: 'Tag',
      render: StatusTag,
    },
    {
      key: 'created_at',
      label: 'Creation Date',
      render: (e) => <span>{e.created_at.split('T')[0]}</span>,
    },
    {
      type: 'divider',
      label: 'description',
      editFn: () => {
        navigate('/app/policy/' + policyData.id + '/edit');
      },
    },
    {
      key: 'owner',
      label: 'Owner',
      render: (e) => <RenderGroupOrUser user={e?.user} group={e.group} />,
    },
    {
      key: 'userdef_id',
      label: 'ID',
    },
    {
      key: 'title',
      label: 'Name',
    },
    {
      type: 'divider',
      label: policyData.current_version ? 'Current Version' : 'No Version Available',
      ...(policyData.current_version && {
        editFn: () => {
          navigate('/app/policy/' + policyData.id + '/' + policyData.current_version.id + '/edit');
        },
      }),
    },
    ...(policyData.current_version
      ? [
        {
          key: 'version',
          label: 'Version ID',
          render: (e) => <span>{e.current_version.userdef_id}</span>,
        },
        {
          key: 'description',
          label: 'Description',
          render: (e) => <span className='whitespace-pre-line'>{e.current_version.description}</span>,
        },
        {
          key: 'attachments',
          label: 'Attachments',
          render: (e) => <RenderFileUploaded fileList={e.current_version.af_policy_version_attachment} />,
        },
      ]
      : []),
  ];
  
  const onSelectedTabChange = (e) => {
    setSelectedTab(e)
  }

  const handleNewConnection = async (type, value) => {
    if (type === 'compliance') {
      await supabase.from('af_policy_compliance').upsert({
        fk_policy_id: policyData.id,
        fk_framework_safeguard_id: value.id,
        fk_project_id: userInfo.project.id
      })
    } else if (type === 'risk') {
      await supabase.from('af_policy_risk').upsert({
        fk_risk_id: value.id,
        fk_policy_id: policyData.id,
        fk_project_id: userInfo.project.id
      })
    } else if (type === 'control') {
      await supabase.from('af_policy_control').upsert({
        fk_control_id: value.id,
        fk_policy_id: policyData.id,
        fk_project_id: userInfo.project.id
      })
    }
    await refetch();
  }

  const handleDeleteConnection = async (type, value) => {
    if (type === 'compliance') {
      await supabase
        .from('af_policy_compliance')
        .delete()
        .eq('fk_policy_id', policyData.id)
        .eq('fk_framework_safeguard_id', value.id)
    } else if (type === 'risk') {
      await supabase
        .from('af_policy_risk')
        .delete()
        .eq('fk_policy_id', policyData.id)
        .eq('fk_risk_id', value.id)
    } else if (type === 'control') {
      await supabase
        .from('af_policy_control')
        .delete()
        .eq('fk_control_id', value.id)
        .eq('fk_policy_id', policyData.id)
    }
    await refetch();
  }

  return (
    <>
      <div className='border-b'>
        <div className='sm:hidden'>
          <select
            id='current-tab'
            name='current-tab'
            className='block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
            defaultValue={selectedTab}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className='hidden sm:block'>
          <nav className='-mb-px ml-2 flex space-x-8'>
            {tabs.map((tab) => (
              <a
                key={tab.name}
                className={classNames(
                  selectedTab === tab.name
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                )}
                aria-current={tab.current ? 'page' : undefined}
                onClick={() => {
                  onSelectedTabChange(tab.name)
                }}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>

      <div>
        {selectedTab === 'Description' && <ElementDetail detailSpec={policy_spec} data={policyData} />}
        {selectedTab === 'Versions' && <PolicyVersioning policyData={policyData} refetch={refetch} />}
        {selectedTab === 'Tasks' && <TasksTable associatedId={policyData?.id} />}
        {selectedTab === 'Connections' &&
          <ConnectionTable
            canEditData={true}
            value={policyData}

            onNewConnection={handleNewConnection}
            onDeleteConnection={handleDeleteConnection}
          />
        }
      </div>
    </>
  )
}
