import { Controller, useForm } from "react-hook-form";
import { ActionButton } from "../form/action-button";
import { TextInput } from "../form/inputs";
import { WorkflowDefinition } from "./workflow-definition";
import { useId } from "react";
import uuid from "react-uuid";

export const WorkflowForm = ({ onSubmit, onCancel, initialData }) => {
  // Dynamically set default values based on initialData or use fallback values
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    control
  } = useForm({
    defaultValues: {
      id: initialData?.id || uuid(),
      name: initialData?.name || "",            // Use initialData name if available
      description: initialData?.description || "", // Use initialData description if available
      workflow_template_steps: initialData?.workflow_template_steps || []     // Use initialData workflow steps if available
    },
    mode: 'onChange',  // Validate in real-time
  });

  const values = watch();

  return (
    <>
      <form id="workflow-edit-form" onSubmit={handleSubmit(onSubmit)}>
        {/* Name Field */}
        <TextInput
          label="Name"
          name="name"
          errorMessage={errors?.name ? 'This field is required, maximum 128 characters' : null}
          register_fn={() => register('name', { required: true, maxLength: 128 })}
        />
        <div className="py-1"></div>

        {/* Description Field */}
        <TextInput
          label="Description"
          name="description"
          rows={5}
          register_fn={() => register('description')}
        />
        <div className="py-1"></div>

        {/* Workflow Field with Custom Validation */}
        <Controller
          control={control}
          name="workflow_template_steps"
          rules={{
            validate: (value) => value?.length > 0 || 'At least one workflow step is required',
          }}
          render={({ field: { value, onChange } }) => (
            <WorkflowDefinition
              label="Workflow"
              error={errors?.workflow_template_steps?.message}
              value={value}
              onChange={onChange}
              edit={true}
            />
          )}
        />
        
        {/* Save and Cancel Buttons */}
        <div className="flex justify-end border-t py-2 gap-2">
          <ActionButton styleType="secondary" type="button" onClick={onCancel}>
            Cancel
          </ActionButton>
          <ActionButton type="submit" disabled={!isValid}>
            Save
          </ActionButton>
        </div>
      </form>
    </>
  );
};
