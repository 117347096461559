import React, { useContext, useEffect, useState } from 'react'

import { useNavigate } from 'react-router'
import { ObjectHeader } from '../components/header/object-header'
import { ObjectSearchFilter } from '../components/header/object-search-filter'
import { RiskColumnFull } from '../components/misc/columns_def'
import { FullPageCard } from '../components/misc/full-page-card'
import { JTable } from '../components/table/j-table'
import { useAuth } from '../supabase'
import { UserInfoContext } from './root-page'
import { RiskOverall } from '../components/dashboard/risk-overall'

// Update filterOptions with the unique values
const filterOptions = [
  {
    section_title: 'Tag',
    key: 'TAg',
    data: [
      {
        label: 'Active',
        value: 'status_active'
      },
      {
        label: 'Draft',
        value: 'status_draft'
      },
      {
        label: 'Archived',
        value: 'status_archived'
      }
    ]
  },
  {
    section_title: 'Strategy',
    key: 'strategy',
    data: [
      {
        label: 'Accept',
        value: 'strategy_accept'
      },
      {
        label: 'Mitigate',
        value: 'strategy_mitigate'
      },
      {
        label: 'Transfer',
        value: 'strategy_transfer'
      },
      {
        label: 'Avoid',
        value: 'strategy_avoid'
      }
    ]
  }
]

const initializeActiveFilters = () => {
  // Try to get filters from localStorage or set defaults if not found
  const storedFilters = localStorage.getItem('activeFiltersRiskPage')
  const initialFilters = storedFilters ? JSON.parse(storedFilters) : {}

  if (!storedFilters) {
    filterOptions.forEach((section) => {
      section.data.forEach((filter) => {
        initialFilters[filter.value] = false // Default state can be set to false or true as needed
      })
    })
  }
  return initialFilters
}

export const AllRiskPage = (navigation, route) => {
  const navigate = useNavigate()

  const { supabase, session } = useAuth()
  const { userInfo } = useContext(UserInfoContext)

  /*------Query Data-------*/
  const [isLoading, setIsLoading] = useState(true)
  const [riskList, setRiskList] = useState([])
  const [queryError, setQuerryError] = useState(null)

  //Query the information of the user and associated project then pass it to all the sub pages
  const fetchData = async () => {
    const { data, error } = await supabase.from('risk')
      .select('*, group(*), user(*)')
      .eq('fk_project_id', userInfo?.project?.id)
      .order('created_at', { ascending: false })

    setQuerryError(error ?? null)
    setRiskList(data ?? [])
    setIsLoading(false)
  }

  //Fetch user dara
  useEffect(() => {
    fetchData()
  }, [supabase])

  const [forceUpdate, setForceUpdate] = useState(false)
  const [filteredValues, setFilteredValues] = useState([])
  const [activeFilters, setActiveFilters] = useState(initializeActiveFilters())

  const handleFilterChange = (e) => {
    const updatedFilters = {
      ...activeFilters,
      [e.target.name]: e.target.checked
    }
    // Create a copy of updatedFilters excluding the 'search' field
    const { search, ...filtersToSave } = updatedFilters;

    setActiveFilters(updatedFilters)
    localStorage.setItem('activeFiltersRiskPage', JSON.stringify(filtersToSave))
  }

  //Filter
  useEffect(() => {
    let filtered = riskList

    filtered = filtered.filter(
      (item) =>
        (activeFilters['status_active'] && item.status === 'ACTIVE') ||
        (activeFilters['status_draft'] && item.status === 'DRAFT') ||
        (activeFilters['status_archived'] && item.status === 'ARCHIVED') ||
        (!activeFilters['status_active'] && !activeFilters['status_draft'] && !activeFilters['status_archived'])
    )

    filtered = filtered.filter(
      (item) =>
        (activeFilters['strategy_accept'] && item.strategy === 'Accept') ||
        (activeFilters['strategy_mitigate'] && item.strategy === 'Mitigate') ||
        (activeFilters['strategy_transfer'] && item.strategy === 'Transfer') ||
        (activeFilters['strategy_avoid'] && item.strategy === 'Avoid') ||
        (!activeFilters['strategy_accept'] &&
          !activeFilters['strategy_mitigate'] &&
          !activeFilters['strategy_transfer'] &&
          !activeFilters['strategy_avoid'])
    )

    // Filter by search term if applicable
    if (activeFilters['search'] && activeFilters['search'] !== '') {
      filtered = filtered.filter((item) => {
        const searchTerm = activeFilters['search'].toLowerCase()

        const titleMatch = item?.title?.toLowerCase().includes(searchTerm)
        const userdefIdMatch = item?.userdef_id?.toString().toLowerCase().includes(searchTerm)

        return titleMatch || userdefIdMatch
      })
    }

    setForceUpdate(!forceUpdate)
    setFilteredValues(filtered)
  }, [riskList, activeFilters])

  const onRowClick = (e) => {
    navigate('/app/risk/' + e.id)
  }

  const handleNewRiskClick = () => {
    navigate('/app/risk/new')
  }

  return (
    <FullPageCard>
      <ObjectHeader title='Risks' primaryButtonText='New Risk' onPrimaryButtonClick={handleNewRiskClick} />
      <ObjectSearchFilter onFilterChange={handleFilterChange} filterOptions={filterOptions} activeFilters={activeFilters} />

      <JTable
        columns={RiskColumnFull}
        value={filteredValues}
        handleRowClick={onRowClick}
        pagination='True'
        key={forceUpdate}
        isLoading={isLoading}
      />
    </FullPageCard>
  )
}
