
export const saveWorkflow = async (supabase, value, projectId) => {
  try {
    // Step 1: Delete existing steps associated with the workflow template
    if (value?.id) {
      const { error: deleteError } = await supabase
        .from('workflow_template_steps')
        .delete()
        .eq('fk_template_id', value?.id);

      if (deleteError) throw deleteError;
    }

    // Step 2: Upsert workflow template
    const { error: templateError } = await supabase
      .from('workflow_template')
      .upsert([{
        id: value.id,
        name: value.name,
        description: value.description,
        fk_project_id: projectId,
      }]);

    if (templateError) throw templateError;

    // Step 3: Insert the workflow template steps
    const steps = value.workflow_template_steps.map((step, index) => ({
      id: step.id,
      step_number: step.step_number,
      fk_project_id: projectId,
      name: step.name,
      description: step.description,
      fk_assigned_group_id: step.assigned.group ? step.assigned.id : null,
      fk_assigned_user_id: !step.assigned.group ? step.assigned.id : null,
      can_add_note: step.can_add_note,
      fk_template_id: value.id,
      step_number: index
    }));

    const { error: stepsError } = await supabase
      .from('workflow_template_steps')
      .upsert(steps);

    if (stepsError) throw stepsError;
  } catch (error) {
    throw error;
  }
};

export const deleteWorkflow = async (supabase, workflowId) => {

  try {
    const { error: deleteStepError } = await supabase
      .from('workflow_template_steps')
      .delete()
      .eq('fk_template_id', workflowId)
    if (deleteStepError) throw deleteStepError;

    const { error } = await supabase
      .from('workflow_template')
      .delete()
      .eq('id', workflowId);

    if (error) throw error;
  } catch (error) {
    throw error;
  }
};
