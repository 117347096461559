import React from 'react'

export default function TablePagination({ total, itemsPerPage, currentPage, onPageChange }) {
  const totalPages = Math.ceil(total / itemsPerPage)

  const handleChange = (event) => {
    onPageChange(parseInt(event.target.value, 10))
  }

  return (
    <div className='flex items-center justify-between border-t border-gray-200 bg-white px-4  sm:px-6'>
      <div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
        <div>
          <p className='text-sm text-gray-700'>
            {'Showing '}
            <span className='font-medium'>{(currentPage - 1) * itemsPerPage + 1}</span> to{' '}
            <span className='font-medium'>{Math.min(currentPage * itemsPerPage, total)}</span>
            {' of '}
            <span className='font-medium'>{total}</span>
            {' results'}
          </p>
        </div>
        <div>
          <label htmlFor='page-select' className='sr-only'>
            Page:
          </label>
          <select
            id='page-select'
            className='mt-1 block pl-3 pr-10 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
            value={currentPage}
            onChange={handleChange}
          >
            {Array.from({ length: totalPages }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                Page {i + 1}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}
