import React from 'react'

export const Tag = ({ text, type }) => {
  return (
    <>
      {type === 'success' && (
        <span className='inline-block default-cursor mr-2 py-1 px-2 bg-green-50 border border-green-200 rounded-sm text-xs font-medium text-green-600 whitespace-nowrap align-middle'>
          {text}
        </span>
      )}
      {type === 'error' && (
        <span className='inline-block default-cursor mr-2 py-1 px-2 bg-red-50 border border-red-200 rounded-sm text-xs font-medium text-red-600 whitespace-nowrap align-middle'>
          {text}
        </span>
      )}
      {type === 'gray' && (
        <span className='inline-block default-cursor mr-2 py-1 px-2 bg-gray-50 border border-gray-200 rounded-sm text-xs font-medium text-gray-600 whitespace-nowrap align-middle'>
          {text}
        </span>
      )}
      {type === 'yellow' && (
        <span className='inline-block default-cursor mr-2 py-1 px-2 bg-yellow-50 border border-yellow-200 rounded-sm text-xs font-medium text-yellow-600 whitespace-nowrap align-middle'>
          {text}
        </span>
      )}
      {type === undefined && (
        <span className='inline-block default-cursor mr-2 py-1 px-2 bg-blue-50 border border-blue-200 rounded-sm text-xs font-medium text-blue-600 whitespace-nowrap align-middle'>
          {text}
        </span>
      )}
    </>
  )
}
