import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ElementDetail } from '../components/element/element-detail';
import { ObjectHeader } from '../components/header/object-header';
import { TaskFullColumn } from '../components/misc/columns_def';
import { FullPageCard } from '../components/misc/full-page-card';
import { LoadingWheel } from '../components/misc/loading-wheel';
import { JTable } from '../components/table/j-table';
import { useAuth } from '../supabase';
import { UserInfoContext } from './root-page';
import { useTaskByExecutor } from '../hooks/useTaskByExecutor';
import { useTaskWithPendingValidationStep } from '../hooks/useTaskWithPendingValidationStep';

const user_spec = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'department',
    label: 'Department',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'associated_groups',
    label: 'Associated Groups',
    render: (e) => (
      <ul>
        {e?.group?.map((g => (
          <li>{g?.name ? g.name : 'Errr'}</li>
        )))
        }
      </ul>
    )
  }
];

export const TodoPage = (navigation, route) => {
  const { supabase } = useAuth();
  const navigate = useNavigate();

  const { userInfo } = useContext(UserInfoContext);
  const groupIdsStr = userInfo?.group.map((e) => (e.id)).join(',')

  const { taskData: taskPendingExecution, isLoading: isLoading1 } = useTaskByExecutor(supabase, userInfo.id, groupIdsStr);
  const { taskData: taskPendingValidation, isLoading: isLoading2 } = useTaskWithPendingValidationStep(supabase, userInfo.id, groupIdsStr);
  console.log('taskPendingValidation', taskPendingValidation)

  const handleRowClick = (row) => {
    navigate('/app/task/' + row?.id);
  }

  return (
    <FullPageCard>
      <ObjectHeader
        title={'TODO: ' + userInfo.name}
        allowFilter={false}
      />
      <ElementDetail
        className='mb-2 border-b'
        detailSpec={user_spec}
        data={userInfo}
      />
      <div className='mt-4 flex flex-col gap-4'>
        <JTable
          title='Pending execution'
          value={taskPendingExecution ?? []}
          columns={TaskFullColumn}
          pagination={true}
          handleRowClick={(e) => { handleRowClick(e) }}
          isLoading={isLoading1}
        />
        <JTable
          title='Pending validation'
          value={taskPendingValidation ?? []}
          columns={TaskFullColumn}
          pagination={true}
          handleRowClick={(e) => { handleRowClick(e) }}
          isLoading={isLoading2}
        />
      </div>
    </FullPageCard>
  )
}