import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import React, { Fragment, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { ActionButton } from '../form/action-button'
import { TextInput } from '../form/inputs'
import { LoadingWheel } from '../misc/loading-wheel'


const Drawer = (props) => {
  const {children, open, onClose} = props;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto relative w-screen max-w-screen-sm'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-500'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-500'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4'>
                      <button
                        type='button'
                        className='relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                        onClick={onClose}
                      >
                        <span className='absolute -inset-2.5' />
                        <span className='sr-only'>Close panel</span>
                        <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white py-4 shadow-xl'>
                    <div className='px-4 sm:px-6'>
                      <Dialog.Title className='text-base font-semibold leading-6 uppercase text-gray-900'>New Version</Dialog.Title>
                    </div>
                    <div className='relative mt-6 flex-1 px-4 border-t pt-2 sm:px-6'>
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

const NewVersionForm = (props) => {
  const { onSubmit } = props;

  const defaultValues = {
    userdef_id: '',
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue
  } = useForm({ defaultValues, mode: 'onBlur' });

  return (
    <>
      <form id='new-task-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='space-y-4'>
          <TextInput
            label='ID'
            name='userdef_id'
            errorMessage={errors?.userdef_id ? 'This field is required, maximum 64 characters' : null}
            register_fn={() => {
              return register('userdef_id', { required: true, maxLength: 64 })
            }}
          />
        </div>

        <div className='flex justify-end mt-2'>
          <ActionButton type='submit' disabled={!isValid}>Create Version</ActionButton>
        </div>
      </form>
    </>
  )
}

export const NewPolicyVersionDrawer = (props) => {
  const { userInfo } = useContext(UserInfoContext)
  const { supabase } = useAuth()

  const { open, onClose,associatedId} = props
  const [isLoading, setIsLoading] = useState(false);


  const handleCreateVersion = async (values) => {
    setIsLoading(true)

    const insertResult = await supabase.from('policy_version').insert([
      {
        fk_project_id: userInfo.project.id,
        fk_policy_id: associatedId,
        userdef_id: values.userdef_id,
      }
    ])
    if (insertResult.error) console.error(insertResult.error)

    onClose(true)
    setIsLoading(false)
  }

  return (
    <Drawer open={open} onClose={onClose}>
      {isLoading && <LoadingWheel />}
      {!isLoading &&
        <NewVersionForm
          onSubmit={handleCreateVersion}
        />
      }
    </Drawer>
  )
}
