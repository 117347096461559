import './App.css'

import uuid from 'react-uuid'

import React, { useState, useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router'

import { FrameworkPage } from './page/framework-page'
import { AllControlPage } from './page/all-control-page'
import { AllPolicyPage } from './page/all-policy-page'
import { AllRiskPage } from './page/all-risk-page'
import { LoginPage } from './page/auth/login-page'
import { TaskPage } from './page/task-page'
import { CompliancePage } from './page/compliance-page'
import { ControlPage } from './page/control-page'
import { GroupDetailPage } from './page/group-detail-page'
import { PolicyPage } from './page/policy-page'
import { RiskPage } from './page/risk-page'
import { RootPage } from './page/root-page'
import { StakeholderPage } from './page/stakeholder-page'
import { TodoPage } from './page/todo-page'
import { UserDetailPage } from './page/user-detail-page'
import { AllAuditPage } from './page/all-audit-page'
import { DashboardPage } from './page/dashboard-page'
import { AllTaskPage } from './page/all-task'
import { UpdatePasswordPage } from './page/auth/update-password-page'
import { useAuth } from './supabase'
import { ReportPage } from './page/report-page'
import { AllFrameworkPage } from './page/all-framework-page'
import { EditPolicyPage } from './page/edit-policy-page'
import { WorkflowPage } from './page/workflow-page'
import { AllWorkflowPage } from './page/all-workflow-page'


function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const { supabase, session } = useAuth();
  const isLogged = session?.user?.id !== undefined;

  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [queryError, setQueryError] = useState(null);

  const fetchData = async () => {
    if (session?.user?.id) {
      const { data, error } = await supabase
        .from('user')
        .select('*, project(*), group(*)')
        .eq('fk_auth_id', session?.user?.id)
        .single();

      setQueryError(error ?? null);
      setUserInfo(data ?? null);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [supabase]);

  useEffect(() => {
    if (isLoading) return;

    if (isLogged) {
      if (userInfo?.password_updated === false) {
        if (location.pathname !== '/auth/update-password') {
          navigate('/auth/update-password');
        }
      } else if (['/', '/app/', '/app', '/auth/login', '/auth/update-password'].includes(location.pathname)) {
        navigate('/app/todo');
      }
    } else {
      if (location.pathname !== '/auth/login') {
        navigate('/auth/login');
      }
    }
  }, [location, userInfo, isLogged, isLoading]);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/app/');
    }
  }, [location]);

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <Routes>
        <Route path='/auth/'>
          <Route index path='login' element={<LoginPage />} />
          <Route index path='update-password' element={<UpdatePasswordPage />} />
        </Route>
        {!isLoading && isLogged && userInfo && userInfo.type === 'SUPERUSER' &&
          <Route path='/app/' element={<RootPage userInfo={userInfo} />}>
            <Route key='blank' index path='blank' element={<div></div>} />
            <Route key='allframeworkpage' index path='framework' element={<AllFrameworkPage />} />
            <Route key='frameworkpage' index path='framework/:id' element={<FrameworkPage />} />
            <Route key='compliancepage' path='compliance/:id' element={<CompliancePage />} />
            <Route key='todopage' index path='todo' element={<TodoPage />} />
            <Route key='allauditpage' index path='audit' element={<AllAuditPage />} />
            <Route key='compliancepage' path='compliance/:id' element={<CompliancePage />} />
            <Route key='reportpage' index path='report' element={<ReportPage />} />


            <Route key='allworkflowpage' index path='workflow' element={<AllWorkflowPage />} />
            <Route key='workflowpage' index path='workflow/:id' element={<WorkflowPage />} />

            <Route key='allpolicypage' index path='policy' element={<AllPolicyPage />} />
            <Route key='newpolicypage' path='policy/new' element={<EditPolicyPage />} />
            <Route key='editpolicy' path='policy/:id/:revisionid/edit' element={<EditPolicyPage />} />
            <Route key='editpolicy' path='policy/:id/edit' element={<EditPolicyPage />} />
            <Route key='policypage' path='policy/:id' element={<PolicyPage />} />

            <Route key='allcontrolpage' index path='control' element={<AllControlPage />} />
            <Route key='controlpage' path='control/:id' element={<ControlPage />} />
            <Route key='alltaskpage' index path='task' element={<AllTaskPage />} />
            <Route key='taskpage' path='task/:id' element={<TaskPage />} />
            <Route key='allriskpage' index path='risk' element={<AllRiskPage />} />
            <Route key='riskpage' path='risk/:id' element={<RiskPage />} />
            <Route key='stakeholderpage' index path='stakeholders' element={<StakeholderPage />} />
            <Route key='groupdetailpage' path='group/:id' element={<GroupDetailPage />} />
            <Route key='userdetailpage' path='user/:id' element={<UserDetailPage />} />
          </Route>
        }
        {!isLoading && isLogged && userInfo && userInfo.type === 'CONTRIBUTOR' &&
          <Route path='/app/' element={<RootPage userInfo={userInfo} />}>
            <Route key='todopage' index path='todo' element={<TodoPage />} />
            <Route key='taskpage' path='task/:id' element={<TaskPage />} />
          </Route>
        }

      </Routes>
    </>
  );
}

export default App;