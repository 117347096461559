import React, { useState, useEffect } from 'react'
import { ObjectHeader } from '../components/header/object-header'
import { ObjectSearchFilter } from '../components/header/object-search-filter'
import { FullPageCard } from '../components/misc/full-page-card'
import { useAuth } from '../supabase';
import { Navigate, useNavigate, useParams } from 'react-router'
import { LoadingWheel } from '../components/misc/loading-wheel'
import { JTable } from '../components/table/j-table'
import { complianceColumnFull } from '../components/misc/columns_def'

const filterOptions = [
  {
    section_title: 'Applicable',
    key: 'Applicable',
    data: [
      {
        label: 'Yes',
        value: 'applicable_yes'
      },
      {
        label: 'No',
        value: 'applicable_no'
      }
    ]
  },
  {
    section_title: 'Other',
    key: 'other',
    data: [
      {
        label: 'Hide section with no safeguard',
        value: 'hide_section_no_sf'
      }
    ]
  }
]

const initializeActiveFilters = () => {
  // Try to get filters from localStorage or set defaults if not found
  const storedFilters = localStorage.getItem('activeFiltersAllCompliance')
  const initialFilters = storedFilters ? JSON.parse(storedFilters) : {}

  if (!storedFilters) {
    filterOptions.forEach((section) => {
      section.data.forEach((filter) => {
        initialFilters[filter.value] = false // Default state can be set to false or true as needed
      })
    })
  }
  return initialFilters
}

export const FrameworkPage = (navigation, route) => {
  const { supabase, session } = useAuth();
  let { id } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true);
  const [complianceData, setComplianceData] = useState({});
  const [frameworkValues, setFrameworkValues] = useState({});
  const [activeFilters, setActiveFilters] = useState(initializeActiveFilters())

  const handleFilterChange = (e) => {
    const updatedFilters = {
      ...activeFilters,
      [e.target.name]: e.target.checked
    }

    setActiveFilters(updatedFilters)
    localStorage.setItem('activeFiltersAllCompliance', JSON.stringify(updatedFilters))
  }

  const fetchData = async () => {
    try {
      // Query user information
      const { data: complianceData, error: userError } = await supabase
        .from('framework')
        .select(
          `
          *,
          framework_section(
            *,
            framework_safeguard(
              *,
              policy(userdef_id),
              control(userdef_id),
              risk(userdef_id)
            )
          ) 
          `
        )
        .eq('id', id)
        .single()

      if (userError) throw userError

      // If data exists, sort framework_section by 'ordre'
      if (complianceData) {
        complianceData.framework_section = complianceData.framework_section.sort((a, b) => a.order - b.order);
        // Optionally, sort framework_safeguard by a specific field if needed
        complianceData.framework_section.forEach(section => {
          section.framework_safeguard = section.framework_safeguard.sort((a, b) => a.order - b.order);
        });
      }

      setComplianceData(complianceData ?? {...complianceData})
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
    setIsLoading(false)
  }

  //Fetch user dara
  useEffect(() => {
    fetchData()
  }, [supabase])


  //Filter
  useEffect(() => {
    let filtered = complianceData?.framework_section ?? [];

    if (activeFilters.applicable_yes || activeFilters.applicable_no) {
      filtered = filtered.map((item) => ({
        ...item,
        framework_safeguard: item.framework_safeguard.filter(
          (sf) =>
            (activeFilters.applicable_yes && sf.applicable === true) ||
            (activeFilters.applicable_no && sf.applicable === false) ||
            (!activeFilters.applicable_no && !activeFilters.applicable_yes)
        )
      }))
    }

    // Filter by search term if applicable
    if (activeFilters['search'] && activeFilters['search'] !== '') {
      filtered = filtered.map((item) => ({
        ...item,
        framework_safeguard: item.framework_safeguard.filter(
          (sf) =>
            sf.title.toLowerCase().includes(activeFilters['search'].toLowerCase()) ||
            sf.userdef_id.toLowerCase().includes(activeFilters['search'].toLowerCase())
        )
      }))
    }

    if (activeFilters.hide_section_no_sf) {
      filtered = filtered.filter((item) => item.framework_safeguard.length > 0)
    }

    setFrameworkValues(filtered)
  }, [complianceData, activeFilters])

  const handleRowClick = (row) => {
    navigate('/app/compliance/' + row.id)
  }

  if(isLoading) return <LoadingWheel/>

  console.log('framework-page', complianceData)

  return (
    <FullPageCard>
      <ObjectHeader
        title={complianceData?.name}
        allowFilter={true}
        filterOptions={filterOptions}
        activeFilters={activeFilters}
        onFilterChange={handleFilterChange}
      />
      <ObjectSearchFilter 
        onFilterChange={handleFilterChange} 
        filterOptions={filterOptions} 
        activeFilters={activeFilters} 
      />

      <div className='flex flex-col gap-4'>
        {frameworkValues.map((d) => (
          <div direction='vertical' size='middle' className='w-full  border-1 bg-white shadow-xs rounded-lg' key={d.id}>
            <JTable
              title={d?.userdef_id + ' - ' + d?.title}
              value={d?.framework_safeguard ?? []}
              columns={complianceColumnFull}
              key={d?.id}
              handleRowClick={(handleRowClick)}
            />
          </div>
        ))}
      </div>
    </FullPageCard>
  )
}
