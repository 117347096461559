// useTask.js
import { useEffect, useState } from 'react';
import { fetchTasksWithPendingValidationStep } from '../services/taskService';

export const useTaskWithPendingValidationStep = (supabase, userId, groupIds) => {
  const [taskData, setTaskData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await fetchTasksWithPendingValidationStep(supabase, userId, groupIds);

      console.log('data', data)

      const uniqueTasks = data?.reduce((acc, task) => {
        if (!acc.some(t => t.id === task.task_id)) {
          acc.push({
            id: task?.task_id,
            userdef_id: task?.userdef_id,
            title: task?.title,
            duedate: task?.duedate,
            executor_user: task?.executor_user_name ? { name: task.executor_user_name } : undefined,
            executor_group: task?.executor_group_name ? { name: task.executor_group_name } : undefined,
            status: task?.task_status,
          });
        }
        return acc;
      }, []) || [];

      setTaskData(uniqueTasks)
    } catch (error) {
      console.error('Error fetching task data:', error);
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (userId || groupIds?.length > 0) fetchData();
  }, [userId, groupIds]);


  return { taskData, isLoading, error, refetch: fetchData };
};
