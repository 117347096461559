import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import uuid from 'react-uuid'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { ActionButton } from '../form/action-button'
import { TextInput } from '../form/inputs'
import { Radio } from '../form/radio'
import { LoadingWheel } from '../misc/loading-wheel'
import { JTable } from '../table/j-table'

const radio_options = [
  {
    primary: 'Superuser',
    secondary:
      'As a SuperUser, the user has full access to all features and settings within the application. They can create and manage other user accounts, set up and modify projects, assign tasks, view all data, and generate reports. This role is suitable for system administrators or top-level managers who need complete oversight and control over the application.',
    value: 'SUPERUSER'
  },
  {
    primary: 'Contributor',
    secondary:
      'As a Contributor, the user has specific access to areas and tasks assigned to them. They can view, edit, and manage documents, tasks, or projects for which they have been given responsibility. This role is ideal for team members or departmental staff responsible for regularly maintaining and updating compliance or risk management activities.',
    value: 'CONTRIBUTOR'
  }
]

export const UserForm = (props) => {
  const { id, GroupTableColumns } = props
  const [disabledButtons, setDisabledButtons] = useState(false)

  const isEditing = id ? true : false
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()
  const { userInfo } = useContext(UserInfoContext)
  const { session, supabase } = useAuth()

  const defaultValues = {
    name: '',
    email: '',
    department: '',
    type: 'CONTRIBUTOR',
    group: []
  }

  const { register, handleSubmit, errors, setValue, control } = useForm({ defaultValues: defaultValues })

  const handleClickCancel = () => {
    navigate('/app/stakeholders')
  }

  useEffect(() => {
    if (id) {
      supabase
        .from('user')
        .select('*, group(*)')
        .eq('id', id)
        .single()
        .then(({ data, error }) => {
          if (error) {
            console.error('Error fetching group:', error)
            return
          }
          // Use setValue to update form fields
          setValue('name', data.name)
          setValue('email', data.email)
          setValue('department', data.department)
          setValue('group', data.group)
          setValue('type', data.type)

          setIsLoading(false)
        })
    }
  }, [id, supabase])

  const createUser = async (values) => {
    const { name, email, password, department, type, projectId } = values;

    return await supabase.functions.invoke('new_user', {
      body: {
        name,
        email,
        password,
        department,
        fk_project_id: projectId,
        type,
      },
    })
  };

  const onSubmit = async (data) => {
    setDisabledButtons(true)
    const newId = uuid()

    if (isEditing) {
      // Update existing user
      const { error } = await supabase
        .from('user')
        .update({
          name: data.name,
          email: data.email,
          department: data.department,
          type: data.type,
          fk_project_id: userInfo.project.id
        })
        .match({ id: id })

      if (error) {
        console.error('Error updating user:', error)
        return
      }

      // Delete any associations for this user that are not in the newUserIds list
      const { error: deleteError } = await supabase.from('af_user_group').delete().eq('fk_user_id', id) // matches the user

      if (deleteError) {
        console.error('Error deleting stale user user relationships:', deleteError)
        return // Early return on error
      }

      // Update af_user_group with the new or updated group ID for each user
      for (const group of data?.group) {
        const { error } = await supabase.from('af_user_group').upsert([
          {
            fk_user_id: id ? id : newId,
            fk_group_id: group.id,
            fk_project_id: userInfo.project.id
          }
        ])

        if (error) {
          console.error('Error af_user_group:', error)
          return
        }
      }
    } else {
      // Insert new user
      const { data: newUserData, error: newUserError } = await createUser({ ...data, projectId: userInfo.project.id })

      console.log('newUserData', newUserData)
      console.log('newUserError', newUserError)
    }

    setDisabledButtons(false)
    navigate('/app/stakeholders')
  }

  if (isEditing && isLoading) {
    return <LoadingWheel />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='space-y-4'>
      <TextInput
        label='Name'
        name='name'
        errorMessage={errors?.name ? 'This field is required' : null}
        register_fn={() => {
          return register('name', { required: true })
        }}
      />
      <TextInput
        label='Email'
        name='email'
        errorMessage={
          errors?.name
            ? 'This field is required'
            : 'An invitation link will be sent to this user. Please make sure that it is a valid email address.'
        }
        register_fn={() => {
          return register('email', { required: true })
        }}
      />
      <TextInput
        label='Department'
        name='department'
        errorMessage={errors?.name ? 'This field is required' : null}
        register_fn={() => {
          return register('department', { required: true })
        }}
      />
      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>Type</label>
        <Controller
          control={control}
          name='type'
          render={({ field: { value, onChange } }) => <Radio options={radio_options} value={value} onChange={onChange} name='type' />}
        />
      </div>
      {isEditing &&
        <div>
          <label className='block text-sm font-medium text-gray-700 mb-1'>Groups</label>
          <Controller
            control={control}
            name='group'
            render={({ field: { value, onChange } }) => (
              <JTable value={value} onChange={onChange} columns={GroupTableColumns} canEditData={true} AddElementModalType={'group'} />
            )}
          />
        </div>
      }
      <div className='flex justify-end gap-2'>
        <ActionButton disabled={disabledButtons} onClick={handleClickCancel} styleType='secondary' type='button'>
          Cancel
        </ActionButton>
        <ActionButton disabled={disabledButtons} type='submit'>
          {isEditing ? 'Save' : 'Create'}
        </ActionButton>
      </div>
    </form>
  )
}
