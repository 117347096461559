import React, { useContext, useState, useEffect } from 'react';
import { UserInfoContext } from '../../page/root-page';
import { useAuth } from '../../supabase';
import { JTable } from '../table/j-table';
import { SmallButton } from '../form/action-button';
import { Tag } from '../../misc/tag';
import { ConfirmationDialog } from '../form/confirmation-dialog';
import { NewPolicyVersionDrawer } from './new-policy-version-drawer';
import { useNavigate } from 'react-router';
import { RenderFileUploaded } from '../element/element-detail';

export const PolicyVersioning = (props) => {
  const { policyData, refetch } = props;
  const { supabase } = useAuth();
  const navigate = useNavigate();

  const [deleteModalOpen, setDeleteModalOpen] = useState(null);
  const [deleteModalLoading, setIsDeleteModalLoading] = useState(false);
  const [newModalVisible, setIsNewModalVisible] = useState(false);
  const { userInfo } = useContext(UserInfoContext);
  const [openIndices, setOpenIndices] = useState([]); // Store open accordions as an array

  // Toggle New Policy Drawer
  const toggleNewModalVisibility = async (shouldRefetch = false) => {
    if (shouldRefetch) await refetch();
    setIsNewModalVisible(!newModalVisible);
  };

  const handleEdit = (e) => {
    navigate(`${e.id}/edit`);
  };

  const handleDelete = (e) => {
    setDeleteModalOpen(e);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(null);
  };

  const handleDeleteModalConfirm = async () => {
    try {
      setIsDeleteModalLoading(true);
      const { error } = await supabase
        .from('policy_version')
        .delete()
        .match({ id: deleteModalOpen?.id });
      if (error) throw error;
      await refetch();
      setDeleteModalOpen(null);
    } catch (error) {
      console.error('Error deleting policy version:', error);
    } finally {
      setIsDeleteModalLoading(false);
    }
  };

  const handleSetCurrent = async (e) => {
    try {
      const { error } = await supabase
        .from('policy')
        .update({ fk_active_version: e.id })
        .eq('id', policyData.id);
      if (error) throw error;
      await refetch();
    } catch (error) {
      console.error('Error updating policy:', error);
    }
  };

  // Avoid full page reload with window reload
  const onRowClick = (e) => {
    navigate(`/app/policy/${policyData.id}/${e.id}`);
    refetch(); // Refresh data without reloading the page
  };

  // Toggle accordion section allowing multiple to be open
  const toggleAccordion = (index) => {
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter(i => i !== index)); // Close the accordion if already open
    } else {
      setOpenIndices([...openIndices, index]); // Open the accordion
    }
  };

  const renderPolicyVersion = (e, index) => {
    const isOpen = openIndices.includes(index);
    const isActive = policyData.fk_active_version === e?.id;

    return (
      <div key={e.id} className="hs-accordion bg-white border border-gray-300 my-2 -mt-px" id={`hs-bordered-heading-${index}`}>
        <button
          onClick={() => toggleAccordion(index)}
          className={`bg-slate-50 hover:bg-slate-100 hs-accordion-toggle ${isOpen ? 'text-blue-600 border-b' : ''} inline-flex items-center gap-x-3 w-full font-semibold text-start text-gray-800 py-3 px-5 hover:text-gray-500`}
          aria-expanded={isOpen}
          aria-controls={`hs-basic-bordered-collapse-${index}`}
        >
          <div className="flex items-center w-full">
            <div className="flex items-center gap-x-2 flex-grow">
              <svg className={`${isOpen ? 'hidden' : 'block'} size-3.5`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg className={`${isOpen ? 'block' : 'hidden'} size-3.5`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M5 12h14"></path>
              </svg>
              <span>{e.userdef_id}</span>
              <span className="text-gray-500 text-xs">- Created {e.created_at.split('T')[0]}</span>
              {isActive && <Tag text={'CURRENT'} />}
            </div>

            <div className="ml-auto flex gap-x-2">
              <a
                className={`hover:underline text-blue-600 hover:text-blue-800 cursor-pointer font-bold uppercase text-xs ${!isActive ? '' : 'disabled text-gray-400 hover:text-gray-400 cursor-default'}`}
                onClick={(event) => { event.stopPropagation(); if (!isActive) handleDelete(e); }}
              >
                Delete
              </a>
              <a
                className="hover:underline text-blue-600 hover:text-blue-800 cursor-pointer font-bold uppercase text-xs border-l px-2 border-r"
                onClick={(event) => { event.stopPropagation(); handleEdit(e); }}
              >
                Edit
              </a>
              <a
                className={`hover:underline text-blue-600 hover:text-blue-800 cursor-pointer font-bold uppercase text-xs ${!isActive ? '' : 'disabled text-gray-400 hover:text-gray-400 cursor-default'}`}
                onClick={(event) => { event.stopPropagation(); if (!isActive) handleSetCurrent(e); }}
              >
                Set Current
              </a>
            </div>
          </div>
        </button>
        <div id={`hs-basic-bordered-collapse-${index}`} className={` hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${isOpen ? 'max-h-screen' : 'max-h-0'}`} role="region" aria-labelledby={`hs-bordered-heading-${index}`}>
          <div className="pb-2 py-4 px-4">
            <span className="uppercase font-bold text-xs">Description</span>
            <p className="text-gray-800 pt-2 pb-4 whitespace-pre-line">{e.description}</p>
            {e.af_policy_version_attachment && e.af_policy_version_attachment.length > 0 ? (
              <div>
                <span className="uppercase font-bold text-xs">Attachments</span>
                <RenderFileUploaded fileList={e.af_policy_version_attachment} />
              </div>
            ) : (
              <span className="uppercase font-bold text-xs">No Attachment</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <ConfirmationDialog
        isOpen={deleteModalOpen !== null}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
        loading={deleteModalLoading}
      />
      <NewPolicyVersionDrawer
        open={newModalVisible}
        onClose={toggleNewModalVisibility}
        associatedId={policyData.id}
      />
      <div className="my-2">
        <SmallButton onClick={() => toggleNewModalVisibility(false)}>New Version</SmallButton>
      </div>
      <div className="hs-accordion-group">
        {policyData?.all_version.map((e, index) => renderPolicyVersion(e, index))}
      </div>
    </>
  );
};
