import React, { useContext, useEffect, useState } from 'react'

import { useNavigate } from 'react-router'
import { ObjectHeader } from '../components/header/object-header'
import { AuditColumns, ControlColumnFull } from '../components/misc/columns_def'
import { FullPageCard } from '../components/misc/full-page-card'
import { JTable } from '../components/table/j-table'
import { useAuth } from '../supabase'
import { UserInfoContext } from './root-page'
import { AuditDrawer } from '../components/modal/audit-drawer'

export const AllAuditPage = (navigation, route) => {
  const navigate = useNavigate()

  const { supabase, session } = useAuth()
  const { userInfo } = useContext(UserInfoContext)

  /*------Query Data-------*/
  const [isLoading, setIsLoading] = useState(true)
  const [auditList, setAuditList] = useState([])
  const [queryError, setQuerryError] = useState(null)

  //Query the information of the user and associated project then pass it to all the sub pages
  const fetchData = async () => {
    const { data, error } = await supabase
      .from('audit_log')
      .select(
        `
        id,
        table_name,
        operation_type,
        time,
        user(name)
        `
      )
      .eq('fk_project_id', userInfo?.project?.id)
      .range(0, 200)
      .order('time', {ascending: false})

    setQuerryError(error ?? null)
    setAuditList(data ?? [])
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [supabase])

  //Drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [drawerAuditId, setDrawerAuditId] = useState(null);

  const handleRowClick = (e) => {
    setDrawerAuditId(e?.id)
    setIsDrawerOpen(true)
  }

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  }

  console.log('data', auditList)

  return (
    <FullPageCard>
      <ObjectHeader title='Audit' />

      <AuditDrawer
        open={isDrawerOpen}
        auditId={drawerAuditId}
        onClose={handleCloseDrawer}
      />
      <JTable
        columns={AuditColumns}
        value={auditList}
        pagination='True'
        isLoading={isLoading}
        handleRowClick={handleRowClick}
      />
    </FullPageCard>
  )
}
