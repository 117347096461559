import { Controller } from 'react-hook-form'
import Select from 'react-select'

export const JSelect = (props) => {
  const { label, name, control, rules, options, placeholder, errorMessage } = props

  return (
    <>
      <label className='block text-sm font-medium text-gray-700 mb-1'>{label}</label>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <>
            <Select
              {...field}
              options={options}
              placeholder={placeholder}
              value={options.find((option) => option.value === field.value)}
              onChange={(option) => field.onChange(option.value)}
            />
            {errorMessage && <span className='text-red-600'>{errorMessage}</span>}
          </>
        )}
      />
    </>
  )
}
