import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router'
import { ComplianceDetail } from '../components/compliance/compliance-detail'
import { ObjectHeader } from '../components/header/object-header'
import { FullPageCard } from '../components/misc/full-page-card'
import { LoadingWheel } from '../components/misc/loading-wheel'
import { EditComplianceDrawer } from '../components/modal/edit-compliance-drawer'
import { useAuth } from '../supabase'

export const CompliancePage = (props) => {
  let { id } = useParams()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { supabase, session } = useAuth()

  /*------Query Data-------*/
  const [isLoading, setIsLoading] = useState(true)
  const [complianceData, setComplianceData] = useState({})

  const fetchData = async () => {
    const { data, error } = await supabase
      .from('framework_safeguard')
      .select(
        `
        *, 
        policy(*),
        risk(*),
        control(*)
      `
      )
      .eq('id', id)
      .single()
    if (error) console.log('compliance-page fetch error', error)

    setComplianceData(data ?? {})
    setIsLoading(false)
  }

  useEffect(() => {
    if(id){
      fetchData();
    }
  }, [supabase])

  const handleEditClick = async () => {
    fetchData();
    setIsDrawerOpen(!isDrawerOpen)
  }

  return (
    <FullPageCard>
      <ObjectHeader title={'Compliance'} backUrl={-1} primaryButtonText='Edit' onPrimaryButtonClick={handleEditClick} />
      {isLoading && <LoadingWheel />}
      {!isLoading && (
        <>
          <ComplianceDetail 
            complianceData={complianceData} 
            refetch={fetchData}
          />
          <EditComplianceDrawer onClose={handleEditClick} open={isDrawerOpen} data={complianceData} />
        </>
      )}
    </FullPageCard>
  )
}
