import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { ActionButton } from '../form/action-button'
import { AddStakeholderInput } from '../form/add-stakeholder-input'
import { TextInput } from '../form/inputs'
import { RadioExecutionScheduler } from '../form/radio-execution-scheduler'
import { Stepper } from '../header/stepper'
import { ErrorNotification } from '../misc/error-notification'
import { LoadingWheel } from '../misc/loading-wheel'

import uuid from 'react-uuid'
import { AddWorkflowInput } from '../form/add-workflow-input'

const errorMessages = {
  required: "This field is required",
  maxLength: (len) => `Maximum length is ${len} characters`,
};

const PanelDescription = (props) => {
  const { register, errors, control, hidden } = props

  return (
    <div className='space-y-4' hidden={hidden}>
      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>Owner</label>
        <Controller
          control={control}
          name='owner'
          rules={{ required: 'Owner is required' }}
          errorMessage={errors?.owner ? 'This field is required' : null}
          render={({ field: { value, onChange } }) =>
            <AddStakeholderInput value={value} onChange={onChange}
            />}
        />
        {errors?.owner && <p className='text-red-500 text-xs italic'>{errors.owner.message}</p>}
      </div>

      <div className="my-6 h-0.5 bg-gradient-to-r from-gray-300 via-gray-400 to-gray-500"></div>

      <TextInput
        label='ID'
        name='userdef_id'
        errorMessage={errors?.userdef_id ? 'This field is required, maximum 12 characters' : null}
        register_fn={() => {
          return register('userdef_id', { required: true, maxLength: 12 })
        }}
      />
      <TextInput
        label='Title'
        name='title'
        errorMessage={errors?.title ? 'This field is required, maximum 128 characters' : null}
        register_fn={() => {
          return register('title', { required: true, maxLength: 128 })
        }}
      />
      <TextInput
        label='Description'
        name='description'
        rows={15}
        errorMessage={errors?.description ? 'This field is required' : null}
        register_fn={() => {
          return register('description', { required: true })
        }}
      />
    </div>
  )
}

const PanelSchedule = (props) => {
  const { register, errors, control, formValues, hidden, isNew } = props

  return (
    <div className='space-y-4' hidden={hidden}>
      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>Control Operator</label>
        <Controller
          control={control}
          name='executor'
          rules={{ required: 'Control Operator is required' }}
          render={({ field: { value, onChange } }) => <AddStakeholderInput value={value} onChange={onChange} />}
        />
        {errors?.executor && <p className='text-red-500 text-xs italic'>{errors.executor.message}</p>}
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>Validation Workflow</label>

        <Controller
          control={control}
          name="workflow_template_id"
          rules={{ required: errorMessages.required }}
          render={({ field: { value, onChange } }) => (
            <AddWorkflowInput value={value} onChange={onChange} />
          )}
        />
        {errors.workflow_template_id && <p className="text-red-500 text-xs italic">{errors.workflow_template_id.message}</p>}
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>Scheduling</label>
        <RadioExecutionScheduler
          control={control}
          rules={{ required: true }}
          value={formValues?.control_strategy}
          register_fn={() => {
            return register('control_strategy')
          }}
        />
      </div>
    </div>
  )
}

export const ControlForm = (props) => {
  const { id } = props

  const isEditing = id ? true : false

  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setIsLoading] = useState(isEditing)
  const [isError, setIsError] = useState(false)

  const steps = [
    { id: 'description', title: 'Description' },
    { id: 'schedule', title: 'Scheduled Tasks' },
  ]

  const navigate = useNavigate()
  const { userInfo } = useContext(UserInfoContext)
  const { supabase } = useAuth()

  const defaultValues = {
    userdef_id: '',
    title: '',
    description: '',
    control_strategy: 'scheduled_control',
    workflow_template_id: "",
    tasks: [],
    connections: {
      compliance: [],
      risk: [],
      policy: []
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    control
  } = useForm({ defaultValues: defaultValues, mode: 'onBlur' })

  const formValues = watch()

  const errorByPanel = {
    description: ['userdef_id', 'title', 'description'].some((field) => errors[field]),
    schedule: ['executor', 'control_schedule'].some((field) => errors[field]),
  }

  const onSubmit = async (data) => {
    setIsLoading(true)

    const isNew = data?.id === undefined
    const newId = isNew ? uuid() : data.id
    console.info('control-form', data)
    try {
      // Insert new control
      let payload = {
        id: newId,
        userdef_id: data.userdef_id,
        title: data.title,
        description: data.description,
        fk_project_id: userInfo.project.id,

        fk_owner_group_id: data.owner.group ? data.owner.id : null,
        fk_owner_user_id: !data.owner.group ? data.owner.id : null,

        //Control schedule
        fk_executor_group_id: data.executor.group ? data.executor.id : null,
        fk_executor_user_id: !data.executor.group ? data.executor.id : null,

        control_strategy: data.control_strategy,
        fk_workflow_template_id:
          !data?.workflow_template_id?.id || data.workflow_template_id.id === 'no-validation'
            ? null
            : data.workflow_template_id.id,
        readable_control_schedule_str: data.control_strategy === 'scheduled_control' ? data.control_schedule.schedule_str : null,
        rrule_control_schedule_str: data.control_strategy === 'scheduled_control' ? data.control_schedule.recurrent_str : null
      }

      const { data: newControlData, error } = await supabase.from('control').upsert([{ ...payload }])

      if (error) throw error

      navigate('/app/control')
    } catch (e) {
      console.log('Error while upserting control', e)
      setIsLoading(false)
      setIsError(true)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (id) {
      // Fetch group data and populate form
      supabase
        .from('control')
        .select(
          `
          *, 
          workflow_template(id, name),
          framework_safeguard(*), 
          policy(*),
          risk(*), 

          owner_group:group!control_fk_owner_group_id_fkey (*),
          owner_user:user!control_fk_owner_user_id_fkey (*),
          executor_group:group!control_fk_executor_group_id_fkey (*),
          executor_user:user!control_fk_executor_user_id_fkey (*)
        `
        )
        .eq('id', id)
        .single()
        .then(({ data, error }) => {
          if (error) {
            console.error('Error fetching group:', error)
            return
          }
          console.log('controol-form data', data)

          setValue('id', data.id)
          setValue('userdef_id', data.userdef_id)
          setValue('title', data.title)
          setValue('description', data.description)

          //review
          setValue('review', data.review_strategy)
          if (data.owner_group) {
            setValue('owner', { ...data.owner_group, comboboxName: data.owner_group.name, group: true })
          }
          if (data.owner_user) {
            setValue('owner', { ...data.owner_user, comboboxName: data.owner_user.name, group: false })
          }
          setValue('review_schedule', {
            schedule_str: data.readable_schedule_str,
            recurrent_str: data.rrule_schedule_str
          })

          //Schedule
          setValue('control_strategy', data.control_strategy)
          setValue('workflow_template_id', { ...data.workflow_template, comboboxName: data.workflow_template?.name })

          setValue(
            'workflow_template_id',
            data?.workflow_template
              ? { ...data.workflow_template, comboboxName: data.workflow_template.name }
              : { id: 'no-validation', comboboxName: 'NO-VALIDATION' }
          );

          if (data.executor_group) {
            setValue('executor', { ...data.executor_group, comboboxName: data.executor_group.name, group: true })
          }
          if (data.executor_user) {
            setValue('executor', { ...data.executor_user, comboboxName: data.executor_user.name, group: false })
          }
          setValue('control_schedule', {
            schedule_str: data.readable_control_schedule_str,
            recurrent_str: data.rrule_control_schedule_str
          })

          setIsLoading(false)
        })
    }
  }, [id, supabase])

  const handleClickCancel = () => {
    navigate('/app/control')
  }

  const handleClickPopupClose = () => {
    setIsError(false)
  }

  const hasNextStep = activeStep < steps.length - 1

  const actionButtonText = isEditing ? 'Save' : 'Create'

  const handleStepperChange = (id) => {
    setActiveStep(id)
  }

  if (isLoading) {
    return <LoadingWheel />
  }

  return (
    <>
      <Stepper errorByPanel={errorByPanel} steps={steps} activeStep={activeStep} onStepperChange={handleStepperChange} />
      <form id='control-edit-form' onSubmit={handleSubmit(onSubmit)}>
        <ErrorNotification
          title={'Error'}
          text={'An error occured during the control update.'}
          show={isError}
          onClose={handleClickPopupClose}
        />
        {/*Stepper content*/}
        <div className='pt-4'>
          <PanelDescription hidden={activeStep !== 0} register={register} control={control} errors={errors} />
          <PanelSchedule
            hidden={activeStep !== 1}
            register={register}
            formValues={formValues}
            control={control}
            errors={errors}
            isNew={id === undefined}
          />
        </div>

        <div className='flex justify-end gap-2 mt-2 border-t pt-2'>
          <ActionButton disabled={isLoading} onClick={handleClickCancel} styleType='secondary' type='button'>
            Cancel
          </ActionButton>
          {hasNextStep && (
            <ActionButton
              disabled={isLoading}
              onClick={() => {
                handleStepperChange(activeStep + 1)
              }}
              styleType='secondary'
              type='button'
            >
              Next
            </ActionButton>
          )}
          {!hasNextStep && (
            <ActionButton disabled={isLoading || !isValid} styleType='primary' type='submit'>
              {actionButtonText}
            </ActionButton>
          )}
        </div>
      </form>
    </>
  )
}
