// useTask.js
import { useEffect, useState } from 'react';
import { fetchTaskData } from '../services/taskService';

export const useTaskData = (supabase, id = null, objectId = null, is_scheduled = null) => {
  const [taskData, setTaskData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await fetchTaskData(supabase, id, objectId, is_scheduled);

      if (data?.task_validation_step) {
        const updatedSteps = data.task_validation_step
          .sort((a, b) => a.step_number - b.step_number)
          .map((step) => { return step; });

        data.task_validation_step = updatedSteps;
      }

      if (id && data[0] != null) setTaskData(data[0]);
      
      else setTaskData(data);

    } catch (error) {
      console.error('Error fetching task data:', error);
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [supabase, id, objectId]);


  return { taskData, isLoading, error, refetch: fetchData };
};
