import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router'
import { ControlDetail } from '../components/control/control-detail'
import { ControlForm } from '../components/control/control-form'
import { ConfirmationDialog } from '../components/form/confirmation-dialog'
import { ObjectHeader } from '../components/header/object-header'
import { FullPageCard } from '../components/misc/full-page-card'
import { LoadingWheel } from '../components/misc/loading-wheel'
import { useAuth } from '../supabase'

export const ControlPage = (props) => {
  const navigate = useNavigate()
  let { id } = useParams()

  if (id === 'new') id = undefined

  const { supabase, session } = useAuth()
  const [isEditing, setIsEditing] = useState(id ? false : true)
  const [deleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleEditClick = () => {
    setIsEditing(true)
  }

  /*------Query Data-------*/
  const [isLoading, setIsLoading] = useState(true)
  const [controlData, setControlData] = useState({})

  //Query the information of the user and associated project then pass it to all the sub pages
  const fetchData = async () => {
    const { data, error } = await supabase
      .from('control')
      .select(
        `
        *, 
        workflow_template(id, name),

        framework_safeguard(*, framework(name)), 
        policy(*),
        risk(*), 

        owner_group:group!control_fk_owner_group_id_fkey (*),
        owner_user:user!control_fk_owner_user_id_fkey (*),

        executor_group:group!control_fk_executor_group_id_fkey (*),
        executor_user:user!control_fk_executor_user_id_fkey (*)
      `
      )
      .eq('id', id)
      .single()

    if (error) console.log('control select error', error)

    // Map the safeguards and add the framework name to each safeguard
    if (data && data.framework_safeguard) {
      data.framework_safeguard = data.framework_safeguard.map((safeguard) => ({
        ...safeguard,
        framework_name: safeguard.framework?.name || 'Unknown Framework', // Add framework name to each safeguard
      }))
    }

    setControlData(data ?? {})
    setIsLoading(false)
  }

  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [supabase])

  const handleTagChange = async (status) => {
    try {
      const { data, error } = await supabase.from('control').update({ status: status }).eq('id', id)

      if (error) throw error
      fetchData()
    } catch (error) {
      console.error('Error control risk:', error)
    }
  }

  const actions_buttons = [
    {
      text: 'Active',
      onClickHandler: () => {
        handleTagChange('ACTIVE')
      },
      disabled: controlData.status === 'ACTIVE'
    },
    {
      text: 'Draft',
      onClickHandler: () => {
        handleTagChange('DRAFT')
      },
      disabled: controlData.status === 'DRAFT'
    },
    {
      text: 'Archived',
      onClickHandler: () => {
        handleTagChange('ARCHIVED')
      },
      disabled: controlData.status === 'ARCHIVED'
    }
  ]

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false)
  }

  const handleClickDelete = () => {
    setIsDeleteModalOpen(true)
  }

  const handleDeleteModalConfirm = async () => {
    if (id) {
      // Check if id is available
      const { error } = await supabase
        .from('control') // Assuming 'user' is your table name
        .delete()
        .match({ id: id }) // Matching the user with the id

      if (error) {
        console.error('Error deleting control:', error)
        return // Optionally handle the error, maybe set state to show an error message
      }
      console.log(`Control with id ${id} deleted successfully`)
      navigate('/app/control')
    } else {
      console.error('No control ID available for deletion')
    }
  }

  console.log('control-page', controlData)

  return (
    <FullPageCard>
      <ConfirmationDialog
        isOpen={deleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
      />
      {!isEditing && (
        <ObjectHeader
          title={controlData?.userdef_id ? controlData.userdef_id : 'Control'}
          backUrl={-1}
          actionButtonText={!isEditing ? 'Tag' : undefined}
          actionButtonOptions={!isEditing ? actions_buttons : undefined}
          secondaryButtonText={!isEditing ? 'Delete' : undefined}
          onSecondaryButtonClick={handleClickDelete}
          primaryButtonText={!isEditing ? 'Edit' : undefined}
          onPrimaryButtonClick={handleEditClick}
          noBorderBottom={!isEditing}
        />
      )}
      {!isEditing && (
        <>
          {isLoading && <LoadingWheel />}
          {!isLoading && <ControlDetail controlData={controlData} refetch={fetchData} />}
        </>
      )}
      {isEditing && <ControlForm id={id} />}
    </FullPageCard>
  )
}
