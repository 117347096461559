import React, { useState } from 'react'

import { useAuth } from '../../supabase'
import { TextInput } from '../../components/form/inputs'
import { AuthCard } from '../../components/auth/auth-card'
import { ActionButton } from '../../components/form/action-button'
import { useNavigate } from 'react-router'
import { useForm } from 'react-hook-form'

const MFAForm = (props) => {
  const {submitForm, isLoading} = props;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'all' })

  return (
    <form id='control-edit-form' onSubmit={handleSubmit(submitForm)} className='flex flex-col items-center gap-4 w-full'>
      <TextInput
        label='MFA'
        name='mfa'
        type="text"
        errorMessage={errors?.mfa?.message}
        register_fn={() => {
          return register('mfa', {
            required: 'MFA is required',
            minLength: { value: 6, message: 'MFA code must be 6 characters' },
            maxLength: { value: 6, message: 'MFA code must be 6 characters' },
            pattern: {
              value: /^[0-9]{6}$/,
              message: 'MFA code must be 6 digits',
            },
          })
        }}
      />
      <ActionButton disabled={!isValid || isLoading} className='w-full mt-8' type="submit">
        Login
      </ActionButton>
    </form>
  )
}

const LoginForm = (props) => {
  const {submitForm} = props;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'all' })

  return (
    <form id='control-edit-form' onSubmit={handleSubmit(submitForm)} className='flex flex-col items-center gap-4 w-full'>
      <TextInput
        label='Email'
        name='email'
        errorMessage={errors?.email?.message}
        register_fn={() => register('email', {
          required: 'Email is required',
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: 'Invalid email address'
          }
        })}
      />
      <TextInput
        label='Password'
        name='password'
        type="password"
        errorMessage={errors?.password?.message}
        register_fn={() => {
          return register('password', {
            required: 'Password is required',
            maxLength: { value: 64, message: 'Password cannot exceed 64 characters' },
            validate: value => {
              const hasUpperCase = /[A-Z]/.test(value);
              const hasNumber = /\d/.test(value);
              const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
              const hasMinLength = value.length >= 8;
              return hasUpperCase && hasNumber && hasSpecialChar && hasMinLength || 'Password does not meet requirements';
            }
          })
        }}
      />
      <ActionButton disabled={!isValid/*isLoading*/} className='w-full' type="submit">
        Login
      </ActionButton>
    </form>
  )
}

export const LoginPage = (navigation, route) => {
  const navigate = useNavigate()
  const { supabase } = useAuth()

  const [step, setStep] = useState('login');
  const [isLoading, setIsLoading] = useState(false);
  const [savedCredentials, setSavedCredentials] = useState(null);

  const submitLoginForm = (values) => {
    setSavedCredentials(values);
    setStep('mfa');
  }

  const authenticate = async (values) => {
    setIsLoading(true)

    //TODO use the MFA
    const mfa = values?.mfa;
    
    const { data, error } = await supabase.auth.signInWithPassword(savedCredentials)
    setIsLoading(false);

    if (error === null) {
      window.location.reload()
    } else {
      setErrorMessage('Invalid login credentials')
    }
  }

  return (
    <div className='h-screen bg-slate-50 flex items-center justify-center'>
      <AuthCard>
        <span className='font-semibold text-gray-800 text-center'>
        </span>
        {step === 'login' &&
          <LoginForm submitForm={submitLoginForm} />
        }
        {step === "mfa" &&
          <MFAForm submitForm={authenticate} isLoading={isLoading}/>
        }
      </AuthCard>
    </div>
  );
}
