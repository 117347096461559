import React, { useContext, useEffect, useState } from 'react'

import { useNavigate } from 'react-router'
import { ObjectHeader } from '../components/header/object-header'
import { ObjectSearchFilter } from '../components/header/object-search-filter'
import { TaskFullColumn } from '../components/misc/columns_def'
import { FullPageCard } from '../components/misc/full-page-card'
import { JTable } from '../components/table/j-table'
import { useAuth } from '../supabase'
import { UserInfoContext } from './root-page'

// Update filterOptions with the unique values
const filterOptions = [
  {
    section_title: 'Due Date',
    key: 'due_date',
    data: [
      {
        label: 'Passed',
        value: 'passed'
      },
      {
        label: 'Upcoming Week',
        value: 'duedate_next_week'
      },
      {
        label: 'Upcoming Month',
        value: 'duedate_next_month'
      },
    ]
  },
  {
    section_title: 'Tag',
    key: 'Tag',
    data: [
      {
        label: 'TODO',
        value: 'status_todo'
      },
      {
        label: 'Pending Approval',
        value: 'status_pending_approval'
      },
      {
        label: 'Approved',
        value: 'status_approved'
      },
    ]
  },
]

const initializeActiveFilters = () => {
  // Try to get filters from localStorage or set defaults if not found
  const storedFilters = localStorage.getItem('activeFiltersTaskPage')
  const initialFilters = storedFilters ? JSON.parse(storedFilters) : {}

  if (!storedFilters) {
    filterOptions.forEach((section) => {
      section.data.forEach((filter) => {
        initialFilters[filter.value] = false // Default state can be set to false or true as needed
      })
    })
  }
  return initialFilters
}

export const AllTaskPage = (navigation, route) => {
  const navigate = useNavigate()

  const { supabase, session } = useAuth()
  const { userInfo } = useContext(UserInfoContext)

  /*------Query Data-------*/
  const [isLoading, setIsLoading] = useState(true)
  const [taskList, setTaskList] = useState([])
  const [queryError, setQuerryError] = useState(null)

  //Query the information of the user and associated project then pass it to all the sub pages
  const fetchData = async () => {
    const { data, error } = await supabase
      .from('task')
      .select(`
        *,
        executor_group:group!public_task_fk_executor_group_id_fkey(name, id),
        executor_user:user!public_task_fk_executor_user_id_fkey(name, id)
      `)
      .eq('fk_project_id', userInfo?.project?.id)
      .order('created_at', { ascending: false })

    setQuerryError(error ?? null)
    setTaskList(data ?? [])
    setIsLoading(false)
  }

  //Fetch user dara
  useEffect(() => {
    fetchData()
  }, [supabase])

  const [forceUpdate, setForceUpdate] = useState(false)
  const [filteredValues, setFilteredValues] = useState([])
  const [activeFilters, setActiveFilters] = useState(initializeActiveFilters())

  const handleFilterChange = (e) => {
    const updatedFilters = {
      ...activeFilters,
      [e.target.name]: e.target.checked
    }
    // Create a copy of updatedFilters excluding the 'search' field
    const { search, ...filtersToSave } = updatedFilters;

    setActiveFilters(updatedFilters)
    localStorage.setItem('activeFiltersTaskPage', JSON.stringify(filtersToSave))
  }

  useEffect(() => {
    let filtered = taskList

    // Filter by status
    filtered = filtered.filter(
      (item) =>
        (activeFilters['status_todo'] && item.status === 'TODO') ||
        (activeFilters['status_pending_approval'] && item.status === 'PENDING_APPROVAL') ||
        (activeFilters['status_approved'] && item.status === 'APPROVED') ||
        (!activeFilters['status_todo'] && !activeFilters['status_pending_approval'] && !activeFilters['status_approved'])
    )

    // Filter by due date
    const now = new Date();
    filtered = filtered.filter((item) => {
      const itemDate = new Date(item.duedate);
      return (
        (activeFilters['passed'] && itemDate < now) ||
        (activeFilters['duedate_next_week'] && itemDate >= now && itemDate <= new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000)) ||
        (activeFilters['duedate_next_month'] && itemDate >= now && itemDate <= new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000)) ||
        (!activeFilters['passed'] && !activeFilters['duedate_next_week'] && !activeFilters['duedate_next_month'])
      );
    });

    // Filter by search term if applicable
    if (activeFilters['search'] && activeFilters['search'] !== '') {
      filtered = filtered.filter((item) => {
        const searchTerm = activeFilters['search'].toLowerCase();
        const titleMatch = item?.title?.toLowerCase().includes(searchTerm);
        const userdefIdMatch = item?.userdef_id?.toString().toLowerCase().includes(searchTerm);
        return titleMatch || userdefIdMatch;
      });
    }

    setForceUpdate(!forceUpdate);
    setFilteredValues(filtered);
  }, [taskList, activeFilters]);

  const onRowClick = (e) => {
    navigate('/app/task/' + e.id)
  }

  return (
    <FullPageCard>
      <ObjectHeader title='Tasks' />

      <ObjectSearchFilter
        onFilterChange={handleFilterChange}
        filterOptions={filterOptions}
        activeFilters={activeFilters}
      />

      <JTable
        columns={TaskFullColumn}
        value={filteredValues}
        handleRowClick={onRowClick}
        pagination='True'
        key={forceUpdate}
        isLoading={isLoading}
      />
    </FullPageCard>
  )
}
