import { ArrowLeftOutlined } from '@ant-design/icons'
import React from 'react'

const LoadingWheel = () => {
  return (
    <div className='w-4 h-4  border-t-2 border-blue-500 border-solid rounded-full animate-spin'></div>
  )
}

export const ActionButton = ({ styleType, children, disabled, loading, className = '', ...additionalProps }) => {
  let buttonStyle = ''

  if (disabled ||loading) {
    buttonStyle = 'bg-gray-300 text-gray-500 cursor-not-allowed'
  } else {
    switch (styleType) {
      case 'secondary':
        buttonStyle = 'hover:bg-gray-100 text-gray-900 font-semibold rounded-md ring-1 ring-gray-300'
        break
      case 'error':
        buttonStyle = 'bg-red-100 hover:bg-red-200 text-red-800 font-semibold rounded-md border border-red-600'
        break
      case 'success':
        buttonStyle = 'bg-green-100 hover:bg-green-200 text-green-800 font-semibold rounded-md border border-green-600'
        break
      default:
        buttonStyle = 'bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-md shadow'
    }
  }

  return (
    <button className={`${buttonStyle} py-2 px-4 rounded whitespace-nowrap flex flex-row gap-4 ${className}`} disabled={disabled} {...additionalProps}>
      {loading && <LoadingWheel />}
      {children}
    </button>
  )
}


export const BackButton = ({ ...additionalProps }) => {
  return (
    <ArrowLeftOutlined {...additionalProps} className='text-gray-700 p-2 rounded hover:bg-gray-100 hover:text-gray-900 cursor-pointer' />
  )
}

export const SmallButton = ({ children, ...additionalProps }) => {
  return (
    <button
      type='button'
      className='text-nowrap inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white'
      {...additionalProps}
    >
      {children}
    </button>
  )
}
