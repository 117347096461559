import React from 'react'

const tabs = [
  { name: 'Users', href: '' },
  { name: 'Groups', href: '' }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const StakeholderTab = ({ selectedTab, onSelectedTabChange }) => {
  return (
    <div className='border-b pt-1'>
      <div className='sm:hidden'>
        <select
          id='current-tab'
          name='current-tab'
          className='block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
          defaultValue={selectedTab}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className='hidden sm:block'>
        <nav className='-mb-px ml-2 flex space-x-8'>
          {tabs.map((tab) => (
            <a
              key={tab.name}
              className={classNames(
                selectedTab === tab.name
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
              )}
              aria-current={tab.current ? 'page' : undefined}
              onClick={() => {
                onSelectedTabChange(tab.name)
              }}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
    </div>
  )
}
