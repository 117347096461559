// supabase.js
import { createClient } from '@supabase/supabase-js'
import React, { createContext, useContext, useEffect, useState } from 'react'

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL
const SUPABASE_API_KEY = process.env.REACT_APP_SUPABASE_API_KEY 

const supabase = createClient(SUPABASE_URL, SUPABASE_API_KEY)

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      setLoading(false)
    })

    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  const value = {
    session,
    supabase
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
