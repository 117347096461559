import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router';
import { ElementDetail } from '../components/element/element-detail';
import { ConfirmationDialog } from '../components/form/confirmation-dialog';
import { ObjectHeader } from '../components/header/object-header';
import { FullPageCard } from '../components/misc/full-page-card';
import { LoadingWheel } from '../components/misc/loading-wheel';
import { UserForm } from '../components/stakeholder/user-form';
import { JTable } from '../components/table/j-table';
import { useAuth } from '../supabase';

const jtable_group_columns = [
  {
    key: "name",
    label: "name",
    style: "text-center w-full",
  },
]

const user_spec = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'department',
    label: 'Department',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'created_at',
    label: 'Creation Date',
    render: (e) => <span>{e.created_at.split('T')[0]}</span>
  },
  {
    key: 'group',
    label: 'Groups',
    render: (data) => (
      <JTable
        columns={jtable_group_columns}
        value={data?.group}
        canEditData={false}
      />
    )
  },
]

export const UserDetailPage = (props) => {
  const navigate = useNavigate();
  let { id } = useParams();

  if (id === "new") id = undefined;

  const { supabase, session } = useAuth();
  const [isEditing, setIsEditing] = useState(id ? false : true);
  const [deleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleEditClick = () => { setIsEditing(true); }

  /*------Query Data-------*/
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [queryError, setQuerryError] = useState(null);

  //Query the information of the user and associated project then pass it to all the sub pages
  const fetchData = async () => {
    const { data, error } = await supabase
      .from('user')
      .select('*, group(*)')
      .eq('id', id)
      .single()

    setQuerryError(error ?? null);
    setUserData(data ?? {});
    setIsLoading(false);
  }

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false)
  }

  const handleClickDelete = () => {
    setIsDeleteModalOpen(true)
  }

  const handleDeleteModalConfirm = async () => {
    if (id) { // Check if id is available
      const { error } = await supabase
        .from('user') // Assuming 'user' is your table name
        .delete()
        .match({ id: id }) // Matching the user with the id

      if (error) {
        console.error("Error deleting user:", error);
        return; // Optionally handle the error, maybe set state to show an error message
      }
      // Optionally handle successful deletion, like redirecting or updating UI
      console.log(`User with id ${id} deleted successfully`);
      // Redirect or update state as necessary
      navigate("/app/stakeholders");
    } else {
      console.error("No user ID available for deletion");
    }
  }

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [supabase]);

  return (
    <FullPageCard>
      <ObjectHeader
        title={"User"}
        backUrl={-1}

        secondaryButtonText={!isEditing ? "Delete" : undefined}
        onSecondaryButtonClick={handleClickDelete}

        primaryButtonText={!isEditing ? "Edit" : undefined}
        onPrimaryButtonClick={handleEditClick}
      />
      <ConfirmationDialog
        isOpen={deleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
      />
      {!isEditing && isLoading &&
        <LoadingWheel />
      }
      {!isEditing && !isLoading &&
        <ElementDetail
          detailSpec={user_spec}
          data={userData}
        />
      }
      {isEditing &&
        <UserForm
          id={id}
          GroupTableColumns={jtable_group_columns}
        />
      }
    </FullPageCard>
  );
}