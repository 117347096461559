import React, { useContext, useEffect, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import uuid from 'react-uuid'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { ActionButton } from '../form/action-button'
import { AddStakeholderInput } from '../form/add-stakeholder-input'
import { TextInput } from '../form/inputs'
import { JSelect } from '../form/j-select'
import { ErrorNotification } from '../misc/error-notification'
import { LoadingWheel } from '../misc/loading-wheel'

const PanelDescription = (props) => {
  const { register, errors, control, hidden, watch, setValue } = props

  const impactProbaOption = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' }
  ]

  const strategyOption = [
    { value: 'Accept', label: 'Accept' },
    { value: 'Mitigate', label: 'Mitigate' },
    { value: 'Avoid', label: 'Avoid' },
    { value: 'Transfer', label: 'Transfer' }
  ]

  const probability = watch('probability')
  const impact = watch('impact')

  useEffect(() => {
    const probValue = probability ? probability : 0
    const impactValue = impact ? impact : 0
    const riskScore = probValue * impactValue

    setValue('risk_score', riskScore)
  }, [probability, impact, setValue])

  return (
    <div className='space-y-4' hidden={hidden}>
      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>Owner</label>
        <Controller
          control={control}
          name='owner'
          rules={{ required: 'Owner is required' }}
          errorMessage={errors?.owner ? 'This field is required' : null}
          render={({ field: { value, onChange } }) => 
            <AddStakeholderInput value={value} onChange={onChange} 
          />}
        />
        {errors?.owner && <p className='text-red-500 text-xs italic'>{errors.owner.message}</p>}
      </div>

      <div className="my-6 h-0.5 bg-gradient-to-r from-gray-300 via-gray-400 to-gray-500"></div>

      <TextInput
        label='ID'
        name='userdef_id'
        errorMessage={errors?.userdef_id ? 'This field is required, maximum 12 characters' : null}
        register_fn={() => {
          return register('userdef_id', { required: true, maxLength: 12 })
        }}
      />
      <TextInput
        label='Title'
        name='title'
        errorMessage={errors?.title ? 'This field is required, maximum 128 characters' : null}
        register_fn={() => {
          return register('title', { required: true, maxLength: 128 })
        }}
      />

      {/* Probability, Impact, and Risk Score on the same row */}
      <div className='flex space-x-4 w-full'>
        <div className='flex-1'>
          <JSelect
            label='Probability'
            name='probability'
            placeholder='Select Probability'
            control={control}
            rules={{ required: true }}
            options={impactProbaOption}
            errorMessage={errors?.probability ? 'Required' : null}
          />
        </div>
        <div className='flex-1'>
          <JSelect
            label='Impact'
            name='impact'
            placeholder='Select Impact'
            control={control}
            rules={{ required: true }}
            options={impactProbaOption}
            errorMessage={errors?.impact ? 'Required' : null}
          />
        </div>
        <div className='flex-1'>
          <TextInput
            label='Risk Score'
            name='risk_score'
            disabled={true}
            register_fn={() => {
              return register('risk_score', { required: true })
            }}
          />
        </div>
        <div className='flex-1'>
          <JSelect
            label='Strategy'
            name='strategy'
            placeholder='Select Strategy'
            control={control}
            rules={{ required: true }}
            options={strategyOption}
            errorMessage={errors?.strategy ? 'Required' : null}
          />
        </div>
      </div>
      <TextInput
        label='Description'
        name='description'
        rows={15}
        errorMessage={errors?.description ? 'This field is required' : null}
        register_fn={() => {
          return register('description', { required: true })
        }}
      />
    </div>
  )
}

export const RiskForm = (props) => {
  const { id } = props

  const isEditing = id ? true : false

  const [isLoading, setIsLoading] = useState(isEditing)
  const [isError, setIsError] = useState(false)

  const navigate = useNavigate()
  const { userInfo } = useContext(UserInfoContext)
  const { supabase } = useAuth()

  const defaultValues = {
    userdef_id: '',
    title: '',
    description: '',
    probability: null,
    strategy: 'Mitigate',
    impact: 1,
    probability: 1,
    risk_score: 1,
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    control
  } = useForm({ defaultValues: defaultValues, mode: 'onBlur' })

  const onSubmit = async (data) => {
    setIsLoading(true)

    const isNew = data?.id === undefined
    const newId = isNew ? uuid() : data.id

    try {
      // Insert new risk
      let payload = {
        id: newId,
        userdef_id: data.userdef_id,
        title: data.title,
        description: data.description,

        impact: data.impact,
        probability: data.probability,
        risk_score: data.risk_score,
        strategy: data.strategy,

        fk_project_id: userInfo.project.id,
        fk_owner_group_id: data.owner.group ? data.owner.id : null,
        fk_owner_user_id: !data.owner.group ? data.owner.id : null,
      }

      const { data: riskData, error } = await supabase.from('risk').upsert([{ ...payload }])

      if (error) throw error

      navigate('/app/risk')
    } catch (e) {
      console.log('Error while upserting policy', e)
      setIsLoading(false)
      setIsError(true)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (id) {
      // Fetch group data and populate form
      supabase
        .from('risk')
        .select(
          `
          *, 
          user(*), 
          group(*), 
          policy(*), 
          framework_safeguard(*), 
          control(*)
        `
        )
        .eq('id', id)
        .single()
        .then(({ data, error }) => {
          if (error) {
            console.error('Error fetching group:', error)
            return
          }

          setValue('id', data.id)
          setValue('userdef_id', data.userdef_id)
          setValue('title', data.title)
          setValue('description', data.description)
          setValue('impact', data.impact)
          setValue('probability', data.probability)

          if (data.group) setValue('owner', { ...data.group, comboboxName: data.group.name, group: true })
          if (data.user) setValue('owner', { ...data.user, comboboxName: data.user.name, group: false })

          setIsLoading(false)
        })
    }
  }, [id, supabase])

  const handleClickCancel = () => {
    navigate(-1)
  }

  const handleClickPopupClose = () => {
    setIsError(false)
  }

  const actionButtonText = isEditing ? 'Save' : 'Create'

  if (isLoading) {
    return <LoadingWheel />
  }

  return (
    <>
      <form id='policy-edit-form' onSubmit={handleSubmit(onSubmit)}>
        <ErrorNotification
          title={'Error'}
          text={'An error occured during the policy update.'}
          show={isError}
          onClose={handleClickPopupClose}
        />

        <PanelDescription
          watch={watch}
          setValue={setValue}
          register={register}
          control={control}
          errors={errors}
        />

        <div className='flex justify-end gap-2 mt-2 border-t pt-2'>
          <ActionButton disabled={isLoading} onClick={handleClickCancel} styleType='secondary' type='button'>
            Cancel
          </ActionButton>
          <ActionButton disabled={isLoading || !isValid} styleType='primary' type='submit'>
            {actionButtonText}
          </ActionButton>
        </div>
      </form>
    </>
  )
}
