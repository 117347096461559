import React, { useContext, useState } from 'react'
import { ElementDetail } from '../element/element-detail'
import { ConnectionTable } from '../table/connection-table'
import { TasksTable } from '../table/tasks-table'
import { useAuth } from '../../supabase'
import { UserInfoContext } from '../../page/root-page'
import { RenderGroupOrUser, RiskStrategyTag, StatusTag } from '../misc/columns_def'
import { Tag } from '../../misc/tag'

const tabs = [
  { name: 'Description', href: '' },
  { name: 'Tasks', href: '' },
  { name: 'Connections', href: '' }
]

const risk_spec = [
  {
    key: 'tag',
    label: 'Tag',
    render: StatusTag,
  },
  {
    key: 'created_at',
    label: 'Creation Date',
    render: (e) => <span>{e.created_at.split('T')[0]}</span>,
  },
  {
    type: 'divider',
    label: 'description',
    key: 'div432'
  },
  {
    key: 'owner',
    label: 'Owner',
    render: (e) => <RenderGroupOrUser user={e?.user} group={e.group} />,
  },
  {
    key: 'userdef_id',
    label: 'User-Defined ID'
  },
  {
    key: 'title',
    label: 'Risk Title'
  },

  {
    key: 'description',
    label: 'Risk Description'
  },
  {
    type: 'divider',
    label: 'Evaluation',
    key: 'div432'
  },
  {
    key: 'strategy',
    label: 'Mitigation Strategy',
    render: RiskStrategyTag
  },

  {
    key: 'probability',
    label: 'Probability',
    render: (e) => <Tag text={e.risk_score} />
  },
  {
    key: 'impact',
    label: 'Impact Level',
    render: (e) => <Tag text={e.risk_score} />
  },
  {
    key: 'risk_score',
    label: 'Risk Score ',
    render: (e) => <Tag text={e.risk_score} />
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const RiskDetail = (props) => {
  const { userInfo } = useContext(UserInfoContext)
  const { supabase } = useAuth()

  const { riskData, refetch } = props

  const [selectedTab, setSelectedTab] = useState('Description')

  const onSelectedTabChange = (e) => {
    setSelectedTab(e)
  }

  const handleNewConnection = async (type, value) => {
    if (type === 'policy') {
      await supabase.from('af_policy_risk').upsert({
        fk_risk_id: riskData.id,
        fk_policy_id: value.id,
        fk_project_id: userInfo.project.id
      })
    } else if (type === 'compliance') {
      await supabase.from('af_compliance_risk').upsert({
        fk_risk_id: riskData.id,
        fk_framework_safeguard_id: value.id,
        fk_project_id: userInfo.project.id
      })
    } else if (type === 'control') {
      await supabase.from('af_control_risk').upsert({
        fk_control_id: value.id,
        fk_risk_id: riskData.id,
        fk_project_id: userInfo.project.id
      })
    }
    await refetch();
  }

  const handleDeleteConnection = async (type, value) => {
    if (type === 'policy') {
      await supabase
        .from('af_policy_risk')
        .delete()
        .eq('fk_risk_id', riskData.id)
        .eq('fk_policy_id', value.id)
    } else if (type === 'compliance') {
      await supabase
        .from('af_compliance_risk')
        .delete()
        .eq('fk_risk_id', riskData.id)
        .eq('fk_framework_safeguard_id', value.id)
    } else if (type === 'control') {
      await supabase
        .from('af_control_risk')
        .delete()
        .eq('fk_risk_id', riskData.id)
        .eq('fk_control_id', value.id)
    }
    await refetch();
  }

  return (
    <>
      <div className='border-b'>
        <div className='sm:hidden'>
          <select
            id='current-tab'
            name='current-tab'
            className='block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
            defaultValue={selectedTab}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className='hidden sm:block'>
          <nav className='-mb-px ml-2 flex space-x-8'>
            {tabs.map((tab) => (
              <a
                key={tab.name}
                className={classNames(
                  selectedTab === tab.name
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                )}
                aria-current={tab.current ? 'page' : undefined}
                onClick={() => {
                  onSelectedTabChange(tab.name)
                }}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
      <div>
        {selectedTab === 'Description' && <ElementDetail detailSpec={risk_spec} data={riskData} />}
        {selectedTab === 'Tasks' && <TasksTable associatedId={riskData?.id} />}
        {selectedTab === 'Connections' &&
          <ConnectionTable
            value={riskData}
            canEditData={true}

            onNewConnection={handleNewConnection}
            onDeleteConnection={handleDeleteConnection}
          />
        }
      </div>
    </>
  )
}
