import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ConfirmationDialog } from '../components/form/confirmation-dialog';
import { ObjectHeader } from '../components/header/object-header';
import { FullPageCard } from '../components/misc/full-page-card';
import { LoadingWheel } from '../components/misc/loading-wheel';
import { WorkflowDefinition } from '../components/workflow/workflow-definition';
import { WorkflowForm } from '../components/workflow/workflow-form';
import { useWorkflowData } from '../hooks/useWorkflowData';
import { deleteWorkflow, saveWorkflow } from '../services/workflowService';
import { useAuth } from '../supabase';
import { UserInfoContext } from './root-page';
import { ElementDetail } from '../components/element/element-detail';

const workflow_spec = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'created_at',
    label: 'Creation Date',
    render: (e) => <span>{e?.created_at?.split('T')[0]}</span>
  },
  {
    key: 'div1',
    type: 'divider'
  },
  {
    key: 'workflow',
    label: 'Workflow',
    render: (row) => (
      <WorkflowDefinition
        value={row.workflow_template_steps}
        edit={false}
      />
    )
  },

];

export const WorkflowPage = () => {
  const { supabase } = useAuth();
  const { userInfo } = useContext(UserInfoContext);
  const navigate = useNavigate();
  const { id: paramId } = useParams();

  // Determine if it's a new workflow or editing an existing one
  const isNewWorkflow = paramId === 'new';
  const [isEditing, setIsEditing] = useState(isNewWorkflow);
  const [isSaving, setIsSaving] = useState(false);

  // Handle delete modal state
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Load workflow data based on `paramId`
  const { workflowData, isLoading, error, refetch } = useWorkflowData(!isNewWorkflow ? paramId : undefined);

  // Effect to reset `isEditing` if we are editing an existing workflow
  useEffect(() => {
    if (!isNewWorkflow) {
      setIsEditing(false);
    }
  }, [paramId, isNewWorkflow]);

  // Handlers for modal actions
  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);

  const handleDelete = async () => {
    try {
      await deleteWorkflow(supabase, paramId);
      navigate('/app/workflow');
    } catch (error) {
      console.error('Error deleting workflow:', error);
    }
  };

  const handleSave = async (workflowValues) => {
    setIsSaving(true)
    try {
      await saveWorkflow(supabase, workflowValues, userInfo.project.id);

      if (isNewWorkflow) {
        setIsEditing(false);  // Exit edit mode after saving
        setIsSaving(false)
        navigate(`/app/workflow/${workflowValues.id}`);
      }

      else {
        await refetch();
        setIsSaving(false)
        setIsEditing(false);  // Exit edit mode after saving
      }
    } catch (error) {
      console.error('Error saving workflow:', error);
    }
  };

  const handleEdit = () => setIsEditing(true);
  const handleCancelEdit = () => {
    if (isNewWorkflow) navigate('/app/workflow')
    else {
      setIsEditing(false);
    }
  }

  // Render loading spinner while loading data
  if (isLoading) {
    return (
      <FullPageCard>
        <LoadingWheel />
      </FullPageCard>
    );
  }

  // Render error message if there was an error loading the workflow
  if (!isLoading && error) {
    return (
      <FullPageCard>
        <p>Error loading workflow. Please try again.</p>
      </FullPageCard>
    );
  }

  return (
    <FullPageCard>
      <ObjectHeader
        title={workflowData?.name ? `Workflow: ${workflowData?.name}` : 'New Workflow'}
        backUrl={-1}
        secondaryButtonText={!isEditing && !isNewWorkflow ? 'Delete' : undefined}
        onSecondaryButtonClick={!isEditing && !isNewWorkflow ? openDeleteModal : undefined}
        primaryButtonText={!isEditing ? 'Edit' : undefined}
        onPrimaryButtonClick={!isEditing ? handleEdit : undefined}
      />

      {/* Confirmation dialog for deleting the workflow */}
      <ConfirmationDialog
        isOpen={deleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
      />

      <div className="flex flex-col gap-4">
        {isSaving ? (  // Show loading wheel during save
          <LoadingWheel />
        ) : !isEditing ? (
          <ElementDetail
            detailSpec={workflow_spec}
            data={workflowData}
          />
        ) : (
          <WorkflowForm
            initialData={workflowData}
            onSubmit={handleSave}
            onCancel={handleCancelEdit}
          />
        )}
      </div>
    </FullPageCard>
  );
};
