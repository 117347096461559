import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { useAuth } from '../../supabase';

const fileSizeInMB = (fileSizeInBytes) => (fileSizeInBytes / 1048576).toFixed(1) + 'MB';

const PDFModal = ({ isOpen, onClose, pdfUrl }) => {
  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-screen-xl p-1 overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg">
              <div className="mt-4 w-full h-[80vh]">
                <iframe
                  src={pdfUrl}
                  className="w-full h-full"
                  title="PDF Preview"
                ></iframe>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const RenderFileUploaded = (props) => {
  const { supabase } = useAuth();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isLoadingFile, setIsLoadingFile] = useState(false);

  const { fileList } = props;

  const onClickElement = async (f) => {
    if (isLoadingFile) return

    if (f.name.toLowerCase().endsWith('.pdf')) {
      setIsLoadingFile(true)
      const { data, error } = await supabase.storage.from('project_data').download(f.file_url);
      setIsLoadingFile(false)

      if (error) console.log('Download error', error);

      // Create a URL for the blob
      const url = window.URL.createObjectURL(data);
      setPdfUrl(url); // Set the URL for rendering the PDF
    }
    //download
    else {
      setIsLoadingFile(true)
      const { data, error } = await supabase.storage.from('project_data').download(f.file_url);
      setIsLoadingFile(false)

      // Create a URL for the blob
      const url = window.URL.createObjectURL(data);
      // Create a temporary <a> element and trigger the download
      const tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.download = f.name; // Or any filename you wish to download as
      document.body.appendChild(tempLink); // Append to the document
      tempLink.click(); // Programmatically click the link to trigger the download
      document.body.removeChild(tempLink); // Clean up
      window.URL.revokeObjectURL(url); // Free up the URL object
    }
  }

  return (
    <>
      {isLoadingFile &&
        <div id="file-loading-popover" className="fixed bottom-4 right-4 z-60 sm:max-w-sm w-full mx-auto p-4">
          <div className="p-4 bg-white border border-gray-300 rounded-lg shadow-lg dark:bg-neutral-800 dark:border-neutral-700">
            <div className="flex items-center gap-x-3">
              <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full border-blue-500 border-t-transparent"></div>
              <div className="grow">
                <p className="text-sm text-gray-700 dark:text-neutral-300">
                  Loading in progress, please wait...
                </p>
              </div>
            </div>
          </div>
        </div>
      }

      <dd className='mt-2 text-sm text-gray-900 sm:col-span-2 mt-4'>
        <ul role='list' className='divide-y divide-gray-100 rounded-md'>
          {fileList?.map((file) => (
            <li
              key={file.attachment_id}
              className='flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6 hover:bg-gray-100 hover:cursor-pointer'
              onClick={() => onClickElement(file)}
            >
              <div className='flex w-0 flex-1 items-center'>
                <FontAwesomeIcon icon={faPaperclip} className='h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' />
                <div className='ml-4 flex min-w-0 flex-1 gap-2'>
                  <span className='truncate text-sm text-gray-700'>{file.name}</span>
                  <span className='flex-shrink-0 text-gray-400'>{fileSizeInMB(file.size)}</span>
                </div>
              </div>
              <div className='ml-4 flex-shrink-0'>
                {file.name.toLowerCase().endsWith('.pdf') &&
                  <span className='font-bold text-xs text-indigo-600 ' >
                    PREVIEW
                  </span>
                }
                {!file.name.toLowerCase().endsWith('.pdf') &&
                  <span className='font-bold text-xs text-indigo-600' >
                    DOWNLOAD
                  </span>
                }
              </div>
            </li>
          ))}
        </ul>
      </dd>
      <PDFModal
        isOpen={pdfUrl != null}
        onClose={() => setPdfUrl(null)}
        pdfUrl={pdfUrl}
      />
    </>
  );
};
export const ElementDetail = ({ detailSpec, data, ...additionalProps }) => {
  return (
    <ul className='' {...additionalProps}>
      {detailSpec.map((item, index) => {
        // Check if the next element is a divider
        const isNextElementDivider = detailSpec[index + 1]?.type === 'divider' || (index + 1) >= detailSpec.length;

        return Array.isArray(item) ? (
          // Render group of elements on the same row
          <li key={`group-${index}`} className={`py-3 border-b ${isNextElementDivider ? 'border-0' : ''}`}>
            <div className='flex flex-row flex-wrap justify-between'>
              {item.map((subItem, subIndex) => (
                subItem.type === 'divider' ? (
                  <div key={`divider-${index}-${subIndex}`} className="flex items-center my-4">
                    <div className="flex-grow border-t border-gray-200"></div>
                    <span className="mx-4 text-gray-500">{item.label}</span>
                    <div className="flex-grow border-t border-gray-200"></div>
                  </div>
                ) : (
                  <div key={subItem.key} className={`flex flex-row grow justify-between ${subIndex !== item.length - 1 ? 'border-r' : ''}`}>
                    <h3 className='ml-4 text-md font-semibold text-gray-800'>{subItem.label}</h3>
                    <div className='text-md text-gray-700'>
                      {subItem.render ? subItem.render(data) : <p className='whitespace-pre-line mr-4'>{data[subItem.key]}</p>}
                    </div>
                  </div>
                )
              ))}
            </div>
          </li>
        ) : (
          item.type === 'divider' ? (
            <div key={`divider-${index}`} className="flex items-center py-2">
              <div className="flex-grow border-t border-gray-400"></div>
              {item.label && <span className="mx-4 font-bold uppercase text-xs text-gray-800">{item.label}</span>}
              {item.editFn &&
                <a
                  className={`text-blue-600 hover:text-blue-800 cursor-pointer font-bold uppercase text-xs border-l px-2 hover:underline`}
                  onClick={(event) => { event.stopPropagation(); item.editFn(); }}
                >
                  Edit
                </a>
              }
              {item.label && <div className="flex-grow border-t border-gray-400"></div>}
            </div>
          ) : (
            <li key={item.key} className={`py-2  ${isNextElementDivider ? '' : 'border-b'}`}>
              <div className='flex flex-row'>
                <h3 className='ml-4 text-md font-semibold text-gray-800 w-1/4'>{item.label}</h3>
                <div className='text-md text-gray-700 w-3/4'>
                  {item.render ? item.render(data) : <p className='whitespace-pre-line'>{data[item.key]}</p>}
                </div>
              </div>
            </li>
          )
        );
      })}
    </ul>
  );
};