import React from 'react'
import { useNavigate } from 'react-router'
import { ActionButton, BackButton } from '../form/action-button'
import { DropdownButton } from '../form/dropdown-button'

export const ObjectHeader = (props) => {
  const navigate = useNavigate()

  const {
    title,

    secondaryButtonText,
    onSecondaryButtonClick,

    primaryButtonText,
    onPrimaryButtonClick,

    actionButtonOptions,
    actionButtonText,

    ExtraInfos,
    backUrl,

    noBorderBottom
  } = props

  const handleClickBack = () => {
    navigate(backUrl)
  }

  return (
    <div className={`flex justify-between items-center pb-3 mb-4 ${noBorderBottom ? '' : 'border-b'}`}>
      <div className='flex items-center space-x-4'>
        {backUrl && <BackButton onClick={handleClickBack} />}
        <div className=''>
          <h1 className='text-4xl font-bold leading-tight text-gray-900'>{title}</h1>
          {ExtraInfos}
        </div>
      </div>
      <div className='flex gap-2'>
        {secondaryButtonText && (
          <ActionButton styleType='secondary' onClick={onSecondaryButtonClick}>
            {secondaryButtonText}
          </ActionButton>
        )}
        {actionButtonOptions && <DropdownButton text={actionButtonText} options={actionButtonOptions} />}
        {primaryButtonText && <ActionButton onClick={onPrimaryButtonClick}>{primaryButtonText}</ActionButton>}
      </div>
    </div>
  )
}
