

import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import React, { Fragment, useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { ActionButton } from '../form/action-button'
import { AddStakeholderInput } from '../form/add-stakeholder-input'
import { TextInput } from '../form/inputs'
import { LoadingWheel } from '../misc/loading-wheel'

const Drawer = (props) => {
  const { children, open, onClose } = props;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto relative w-screen max-w-screen-md'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-500'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-500'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4'>
                      <button
                        type='button'
                        className='relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                        onClick={onClose}
                      >
                        <span className='absolute -inset-2.5' />
                        <span className='sr-only'>Close panel</span>
                        <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white py-4 shadow-xl'>
                    <div className='px-4 sm:px-6'>
                    </div>
                    <div className='relative mt-6 flex-1 px-4 border-t pt-2 sm:px-6'>
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

const WorkflowStepForm = (props) => {
  const { defaultValues, onClose } = props;

  const defValues = {
    ...{
      description: '',
      name: '',
      assigned: '',
      can_add_note: true,
      assigned: null
    },
    ...defaultValues
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue
  } = useForm({ defaultValues: defValues, mode: 'onBlur' });

  const handleCheckboxChange = (event) => {
    setValue(event.target.name, event.target.checked, { shouldValidate: true });
  }

  const onSubmit = (values, event) => {
    onClose(values)
  }

  return (
    <>
      <form id='new-task-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='space-y-4'>
          <div>
            <label className='block text-sm font-medium text-gray-700 mb-1'>Assigned</label>
            <Controller
              control={control}
              name='assigned'
              rules={{ required: 'Assigned is required' }}
              errorMessage={errors?.assigned ? 'This field is required' : null}
              render={({ field: { value, onChange } }) => (
                <AddStakeholderInput value={value} onChange={onChange} />
              )}
            />
            {errors?.assigned && <p className='text-red-500 text-xs italic'>{errors.assigned.message}</p>}
          </div>
          <TextInput
            label='Name'
            name='name'
            errorMessage={errors?.name ? 'This field is required, maximum 128 characters' : null}
            register_fn={() => register('name', { required: true, maxLength: 128 })}
          />

          <TextInput
            label='Description'
            name='description'
            rows={10}
            errorMessage={errors?.description ? 'This field is required' : null}
            register_fn={() => register('description', { required: true })}
          />

          {/* Checkbox for Can Add Note */}
          <div className='flex items-center'>
            <input
              type='checkbox'
              id='can_add_note'
              name='can_add_note'
              className='h-4 w-4 text-blue-600 border-gray-300 rounded'
              {...register('can_add_note')}
              onChange={handleCheckboxChange}
            />
            <label htmlFor='can_add_note' className='ml-2 block text-sm text-gray-700'>
              Allow Notes
            </label>
          </div>

          <div className='flex justify-end mt-2'>
            <ActionButton type='button' onClick={handleSubmit(onSubmit)} disabled={!isValid}>Save</ActionButton>
          </div>
        </div>
      </form>
    </>
  );
}

export const WorkflowDrawer = (props) => {
  const { userInfo } = useContext(UserInfoContext)
  const { supabase } = useAuth()

  const { open, onClose, defaultValues } = props

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Drawer open={open} onClose={onClose}>
      {isLoading && <LoadingWheel />}
      {!isLoading &&
        <>
          <WorkflowStepForm
            onClose={onClose}
            defaultValues={defaultValues}
          />
        </>
      }
    </Drawer>
  )
}
