import React, { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { ConfirmationDialog } from '../components/form/confirmation-dialog'
import { ObjectHeader } from '../components/header/object-header'
import { FullPageCard } from '../components/misc/full-page-card'
import { LoadingWheel } from '../components/misc/loading-wheel'
import { TaskDetail } from '../components/task/task-detail'
import { useTaskData } from '../hooks/useTaskData'
import { deleteTask } from '../services/taskService'
import { useAuth } from '../supabase'
import { UserInfoContext } from './root-page'

export const TaskPage = (navigation, route) => {
  const navigate = useNavigate()
  let { id } = useParams()

  const { userInfo } = useContext(UserInfoContext)
  const { supabase } = useAuth()
  const { taskData, isLoading, error, refetch } = useTaskData(supabase, id)
  const [isSaving, setIsSaving] = useState(false)

  const [deleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleDeleteModalConfirm = async () => {
    const { data, error } = deleteTask(supabase, id)
    if (error) console.error('Error deleting task:', error)

    navigate(-1)
  }

  const handleOnSubmitTaskSubmission = async (values) => {
    setIsSaving(true)
    try {
      await supabase.functions.invoke('save-task-initial-submission', {
        body: {
          taskId: taskData?.id,
          taskData: { ...values, status: 'PENDING_APPROVAL' }
        }
      })
      await refetch()
    }
    catch (error) { console.error(error) }
    finally { setIsSaving(false) }
  }

  const handleOnSubmitStepReview = async (stepId, formData) => {
    const action = document.activeElement.value; // Get the button value (approve/decline)

    try {
      const data = await supabase.functions.invoke('updatetask', {
        body: {
          stepId: stepId,
          newStatus: action,
          note: formData?.note
        },
      })
      await refetch()
    }
    catch (e) { console.error('updatetask error', e) }
    finally { setIsSaving(false) }
  };

  if (isLoading) return <LoadingWheel />;
  if (error) return <div>Error loading task: {error.message}</div>;

  console.log('task-page', taskData)

  if (isSaving) {
    return <LoadingWheel />
  }

  return (
    <FullPageCard>
      <ObjectHeader
        title={'Task: ' + taskData?.userdef_id}
        backUrl={-1}

        secondaryButtonText={'Delete'}
        onSecondaryButtonClick={() => setIsDeleteModalOpen(true)}
      />
      <ConfirmationDialog
        isOpen={deleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteModalConfirm}
      />
      <TaskDetail
        taskData={taskData}
        onSubmitStepReview={handleOnSubmitStepReview}
        onSubmitTaskSubmission={handleOnSubmitTaskSubmission}
      />
    </FullPageCard>
  )
}
