import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router'
import { ElementDetail } from '../components/element/element-detail'
import { ObjectHeader } from '../components/header/object-header'
import { FullPageCard } from '../components/misc/full-page-card'
import { LoadingWheel } from '../components/misc/loading-wheel'
import { GroupForm } from '../components/stakeholder/group-form'
import { JTable } from '../components/table/j-table'
import { useAuth } from '../supabase'
import { ConfirmationDialog } from '../components/form/confirmation-dialog'

const jtable_user_columns = [
  {
    key: 'name',
    label: 'name',
    style: 'text-center px-8'
  },
  {
    key: 'email',
    label: 'email',
    style: 'text-center px-8'
  },
  {
    key: 'department',
    label: 'department',
    style: 'text-center px-8'
  }
]

const group_spec = [
  {
    key: 'name',
    label: 'Name'
  },
  {
    key: 'created_at',
    label: 'Creation Date',
    render: (e) => <span>{e.created_at.split('T')[0]}</span>
  },
  {
    key: 'user',
    label: 'Users',
    render: (data) => <JTable columns={jtable_user_columns} value={data?.user} canEditDaa={false} />
  }
]

export const GroupDetailPage = (props) => {
  const navigate = useNavigate()
  let { id } = useParams()
  if (id === 'new') id = undefined

  const { supabase, session } = useAuth()

  const [isEditing, setIsEditing] = useState(id ? false : true)
  const [deleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleEditClick = () => {
    setIsEditing(true)
  }

  /*------Query Data-------*/
  const [isLoading, setIsLoading] = useState(true)
  const [groupData, setGroupData] = useState({})
  const [queryError, setQuerryError] = useState(null)

  //Query the information of the user and associated project then pass it to all the sub pages
  const fetchData = async () => {
    const { data, error } = await supabase.from('group').select('*, user(*)').eq('id', id).single()

    setQuerryError(error ?? null)
    setGroupData(data ?? {})
    setIsLoading(false)
  }

  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [supabase])

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false)
  }

  const handleClickDelete = () => {
    setIsDeleteModalOpen(true)
  }

  const handleDeleteModalConfirm = async () => {
    if (id) {
      // Check if id is available
      const { error } = await supabase
        .from('group') // Assuming 'user' is your table name
        .delete()
        .match({ id: id }) // Matching the user with the id

      if (error) {
        console.error('Error deleting user:', error)
        return // Optionally handle the error, maybe set state to show an error message
      }
      // Optionally handle successful deletion, like redirecting or updating UI
      console.log(`User with id ${id} deleted successfully`)
      // Redirect or update state as necessary
      navigate('/app/stakeholders')
    } else {
      console.error('No user ID available for deletion')
    }
  }

  return (
    <FullPageCard>
      <ObjectHeader
        title={'Group'}
        backUrl={-1}
        secondaryButtonText={!isEditing ? 'Delete' : undefined}
        onSecondaryButtonClick={handleClickDelete}
        primaryButtonText={!isEditing ? 'Edit' : undefined}
        onPrimaryButtonClick={handleEditClick}
      />
      <ConfirmationDialog
        isOpen={deleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
      />
      {!isEditing && isLoading && <LoadingWheel />}
      {!isEditing && !isLoading && <ElementDetail detailSpec={group_spec} data={groupData} />}
      {isEditing && <GroupForm id={id} UserTableColumns={jtable_user_columns} />}
    </FullPageCard>
  )
}
