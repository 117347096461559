import React, { useContext, useEffect, useState } from 'react'
import { JTable } from '../components/table/j-table';
import { ObjectHeader } from '../components/header/object-header'
import { FullPageCard } from '../components/misc/full-page-card'
import { UserInfoContext } from './root-page';
import { useAuth } from '../supabase';
import { useNavigate } from 'react-router';
import { Tag } from '../misc/tag';

const columns = [
  { key: 'name', label: 'Framework', style: 'text-center break-words ' },
  { key: 'created_at', label: 'Creation Date', render: (e) => <span>{e.created_at.split('T')[0]}</span>, style: 'text-center whitespace-nowrap' },
  { key: 'applicableSafeguardsCount', label: 'Applicable', style: 'text-center whitespace-nowrap', render:(e)=> <Tag text={e?.applicableSafeguardsCount + '/' + e?.totalSafeguardsCount}/> },
  { key: 'tag', label: 'Tag', style: 'text-center', render: (e) => <Tag text={e.tag} /> },
]

export const AllFrameworkPage = (navigation, route) => {
  const navigate = useNavigate()
  
  const { supabase, session } = useAuth()
  const { userInfo } = useContext(UserInfoContext)

  /*------Query Data-------*/
  const [isLoading, setIsLoading] = useState(true)
  const [frameworkData, setFrameworkData] = useState([])

  //Query the information of the user and associated project then pass it to all the sub pages
  const fetchData = async () => {
    const { data, error } = await supabase
      .from('framework')
      .select(
        `
          *,
          framework_safeguard (
            applicable
          )
        `
      )
      .eq('fk_project_id', userInfo?.project?.id)

    if (error){
       console.log('all-framework-page fetch error', error)
       setIsLoading(false)
       return
    }
    
    const frameworksWithApplicableCount = data.map((framework) => {
      const applicableSafeguardsCount = framework.framework_safeguard.filter(safeguard => safeguard.applicable).length;
    
        return {
          ...framework,
          applicableSafeguardsCount, // Add the count of applicable safeguards
          totalSafeguardsCount: framework.framework_safeguard.length, // Add the count of applicable safeguards
        };
    });

    setFrameworkData(frameworksWithApplicableCount ?? [])
    setIsLoading(false)
  }

  const onRowClick = (e) => {
    navigate('/app/framework/' + e.id)
  }

  //Fetch user dara
  useEffect(() => {
    fetchData()
  }, [supabase])

  console.log('fetchData', frameworkData)

  return (
    <FullPageCard addClassname="max-w-3xl">
      <ObjectHeader title='Frameworks' />
      <JTable
        columns={columns}
        value={frameworkData}
        pagination={false}
        defaultSortedColumn={'creation_date'}
        defaultSortOrder='asc'
        handleRowClick={onRowClick}
        isLoading={isLoading}
      />
    </FullPageCard>
  )
}