import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router'
import { ObjectHeader } from '../components/header/object-header'
import { FullPageCard } from '../components/misc/full-page-card'
import { LoadingWheel } from '../components/misc/loading-wheel'
import { RiskDetail } from '../components/risk/risk-detail'
import { RiskForm } from '../components/risk/risk-form'
import { useAuth } from '../supabase'
import { ConfirmationDialog } from '../components/form/confirmation-dialog'

export const RiskPage = () => {
  const navigate = useNavigate()
  let { id } = useParams()

  if (id === 'new') id = undefined

  const { supabase } = useAuth()
  const [isEditing, setIsEditing] = useState(id ? false : true)
  const [deleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleEditClick = () => {
    setIsEditing(true)
  }

  /*------Query Data-------*/
  const [isLoading, setIsLoading] = useState(true)
  const [riskData, setRiskData] = useState({})

  //Query the information of the user and associated project then pass it to all the sub pages
  const fetchData = async () => {
    const { data, error } = await supabase
      .from('risk')
      .select(
        `
        *, 
        framework_safeguard(*, framework(name)), 
        control(*), 
        policy(*),
        user(*),
        group(*)
      `
      )
      .eq('id', id)
      .single()

    if (error) console.log('risk-page fetch error', error)

    // Map the safeguards and add the framework name to each safeguard
    if (data && data.framework_safeguard) {
      data.framework_safeguard = data.framework_safeguard.map((safeguard) => ({
        ...safeguard,
        framework_name: safeguard.framework?.name || 'Unknown Framework', // Add framework name to each safeguard
      }))
    }

    setRiskData(data ?? {})
    setIsLoading(false)
  }

  const handleTagChange = async (status) => {
    try {
      const { _data, error } = await supabase.from('risk').update({ status: status }).eq('id', id)

      if (error) throw error
      fetchData()
    } catch (error) {
      console.error('Error updating risk:', error)
    }
  }

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false)
  }

  const handleClickDelete = () => {
    setIsDeleteModalOpen(true)
  }

  const handleDeleteModalConfirm = async () => {
    if (id) {
      // Check if id is available
      const { error } = await supabase
        .from('risk') // Assuming 'user' is your table name
        .delete()
        .match({ id: id }) // Matching the user with the id

      if (error) {
        console.error('Error deleting risk:', error)
        return // Optionally handle the error, maybe set state to show an error message
      }

      console.log(`Risk with id ${id} deleted successfully`)
      navigate('/app/risk')
    } else {
      console.error('No risk ID available for deletion')
    }
  }

  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [supabase])

  const actions_buttons = [
    {
      text: 'Active',
      onClickHandler: () => {
        handleTagChange('ACTIVE')
      },
      disabled: riskData.status === 'ACTIVE'
    },
    {
      text: 'Draft',
      onClickHandler: () => {
        handleTagChange('DRAFT')
      },
      disabled: riskData.status === 'DRAFT'
    },
    {
      text: 'Archived',
      onClickHandler: () => {
        handleTagChange('ARCHIVED')
      },
      disabled: riskData.status === 'ARCHIVED'
    }
  ]

  console.log('risk-page', riskData)

  return (
    <FullPageCard>
      {!isEditing && (
        <ObjectHeader
          title={riskData?.userdef_id ? riskData.userdef_id : 'Risk'}
          backUrl={-1}
          actionButtonText={!isEditing ? 'Tag' : undefined}
          actionButtonOptions={!isEditing ? actions_buttons : undefined}
          secondaryButtonText={!isEditing ? 'Delete' : undefined}
          onSecondaryButtonClick={handleClickDelete}
          primaryButtonText={!isEditing ? 'Edit' : undefined}
          onPrimaryButtonClick={handleEditClick}
          noBorderBottom={!isEditing}
        />
      )}
      <ConfirmationDialog
        isOpen={deleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
      />
      {!isEditing && (
        <>
          {isLoading && <LoadingWheel />}
          {!isLoading && <RiskDetail riskData={riskData} refetch={fetchData}/>}
        </>
      )}
      {isEditing && 
        <>
          <ObjectHeader title={"Edit Risk"} />
          <RiskForm id={id} />
        </>
      }
    </FullPageCard>
  )
}
