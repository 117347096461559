import React from 'react'

export const TextInput = (props) => {
  const { label, name, errorMessage, register_fn, rows = 1, disabled = false, ...additionalProps } = props

  const inputProps = register_fn ? register_fn() : {}

  return (
    <div className='w-full'>
      <label htmlFor={name} className='block text-sm font-medium text-gray-800'>
        {label}
      </label>
      {rows > 1 ? (
        <textarea
          id={name}
          name={name}
          {...inputProps}
          {...additionalProps}
          rows={rows}
          disabled={disabled}
          className={`mt-1 block w-full px-2 py-1 bg-white border border-gray-200 rounded-md shadow-sm text-sm ${
            disabled ? 'bg-gray-100' : 'focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400'
          } transition-all ease-in-out duration-150`}
        />
      ) : (
        <input
          id={name}
          name={name}
          autoComplete='off'
          type='text'
          {...inputProps}
          {...additionalProps}
          disabled={disabled}
          className={`mt-1 block w-full px-2 py-1 bg-white border border-gray-200 rounded-md shadow-sm text-sm ${
            disabled ? 'bg-gray-100 opacity-70' : 'focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400'
          } transition-all ease-in-out duration-150`}
        />
      )}
      {errorMessage && <span className='text-red-500 text-xs'>{errorMessage}</span>}
    </div>
  )
}
